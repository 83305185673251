.dc-spinner {
  position: relative;
  display: inline-block;
  width: rempx(48px);
  height: rempx(48px);
  border-radius: 50%;

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 5px solid transparent;
    border-right: 6px solid currentColor;
    border-top: 6px solid currentColor;
    animation: spin linear infinite 500ms;
    border-radius: 50%;
    opacity: .5;
  }

  &:before {
    animation-duration: 1250ms;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}
