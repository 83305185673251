.verification-sign {
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items:center;
  justify-content: center;
  width: rempx(154px);
  height: rempx(154px);
  background-color: $light-gray;
  border-radius: 50%;
  border: 2px solid $white;
  box-shadow: 3px 0 15px rgba($black, .16);
  font-size: rempx(44px);
  color: $white;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    display: none;
    width: rempx(372px);
    height: rempx(250px);
    transform: translateX(-50%);
    background-image: url('../../../assets/images/celebration.svg');
    background-size: cover;
  }

  &.is-verified {
    background-color: $celebration-bg;
    
    &:before {
      display: block;
    }
  }
}