.break-word {
    word-break: break-word;
}

.text-light-gray {
    color: $light-gray !important;
}

.text-dark {
    color: $dark;
}

.text-dark-gray {
    color: $dark-gray;
}

.text-darker-gray {
    color: $darker-gray;
}

.text-dc-light-gray {
    color: $dc-light-grey;
}

.text-dc-gray {
    color: $dc-gray;
}

.text-white {
    color: $white;
}

.text-red {
    color: #E74C3C !important;
}

.text-off-black {
    color: $offblack;
}

.text-lighter-blue {
    color: $dc-lighter-blue;
}

.text-dc-black {
    color: $dc-black;
}

.text-dc-dark-blue {
    color: $dc-dark-blue;
}

.text-hover-light-gray {
    transition-property: color;
    transition-duration: 200ms;
    &:hover {
        color: $light-gray;
    }
}

.text-border-color {
    color: $border-color;
}

.border-radius-circle {
    border-radius: 50%;
}

.text-3xsmall {
    font-size: rempx(8);
}

.text-2xsmall {
    font-size: rempx(10);
}

.text-xsmall {
    font-size: rempx(12);
}

.text-small {
    font-size: rempx(14);
}

.text-normal {
    font-size: rempx(16);
}

.text-medium {
    font-size: rempx(18);
}

.text-large {
    font-size: rempx(20);
}

.text-xlarge {
    font-size: rempx(22);
}

.text-2xlarge {
    font-size: rempx(24);
}

.text-3xlarge {
    font-size: rempx(30);
}

.text-uppercase {
    text-transform: uppercase
}

.text-capitalize {
    text-transform: capitalize;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.white-space-nowrap {
    white-space: nowrap;
}

.heading-medium {
    font-size: rempx(30);
}

.bg-light-gray {
    background-color: $controls-bg;
}

.bg-lightest-gray {
    background-color: $lightest-gray;
}

.bg-red {
    background-color: #E74C3C;
}

.btn-bg-cancel {
    background-color: $dc-cancel-red;
    &:hover {
        background-color: $dc-cancel-red-hover;
    }
}

.border-1 {
    border: 1px solid;
}

.border-2 {
    border: 2px solid;
}

.border-radius-4 {
    border-radius: rempx(4px);
}

.border-radius-8 {
    border-radius: rempx(8px);
}

.border-radius-10 {
    border-radius: rempx(10px);
}

.border-radius-25 {
    border-radius: rempx(25px);
}

.border-radius-50 {
    border-radius: rempx(50px);
}

.border-bottom-control {
    border-bottom: 1px solid $border-color;
}

.border-top-1 {
    border-top: 1px solid;
}

.dashed-db-border {
    border: 1px dashed #DBDBDB;
}

.max-height-280 {
    max-height: 280px;
}

.min-width-223 {
    min-width: 223px;
}

.min-width-230 {
    min-width: 230px;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.gray-placeholder {
    input::placeholder {
        color: $light-gray;
    }
}

.my {
    &-50 {
        $size: 50px;
        margin-top: rempx($size);
        margin-bottom: rempx($size);
    }

    &-100 {
        $size: 100px;
        margin-top: rempx($size);
        margin-bottom: rempx($size);
    }
}

.custom-checkbox-in-border {
    position: relative;
    border: 1px solid $light-gray;

    &.top-left {
        .custom-checkbox {
            position: absolute;
            top: rempx(-8);
            left: rempx(17);
        }
    }
}

.flex-all-1 {
    flex: 1;
}

.hover-effect {
    transition-property: color;
    transition-duration: 200ms;

    &:hover {
        color: $primary;
    }
}

.hover-item-effect {
    transition-property: background-color;
    transition-duration: 200ms;

    &:hover {
        background-color: #f8f8f8;
    }
}

.table-spacing-1 {
    border-collapse: separate;
    border-spacing: 1rem;

    @media (max-width: 834px) {
        border-spacing: .5rem;
    }
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.top-0 {
    top: 0
}

.left-0 {
    left: 0
}

.opacity-8 {
    opacity: 0.8;
}

.mx-22 {
    margin-right: rempx(22);
    margin-left: rempx(22);
}

.mxw-210 {
    max-width: 210px;
}

.bg-fafafa {
    background-color: #fafafa;
}

.div-36 {
    width: rempx(36);
    height: rempx(36);
}

.user-icon-shadow {
    box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.24);
}
