@each $color, $value in $status-colors {
    .border-status-#{$color} {
        border-color: $value !important;
    }
}

@each $color, $value in $dc-colors {
    .border-color-#{$color} {
        border-color: $value !important;
    }
}
