.email-select {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: rempx(12px);

    &-label {
        color: $primary;
        margin-right: rempx(8px);
        font-size: rempx(14px);
    }

    &-item {
        user-select: none;

        &:not(:last-child):after {
            content: ',';
            margin-right: rempx(6px);
            margin-left: rempx(2px);
        }
    }

    &-input {
        display: block;
        outline: none !important;
        background-color: transparent;
        border: 0;
        font-size: rempx(12px);
        min-width: 0;
        width: 120px;
    }

    &-options-scrollbar {
        max-height: 160px;
    }

    &-option {
        font-size: rempx(14px);
        cursor: pointer;
        padding: rempx(4px);
        padding-right: rempx(16px);
        padding-left: rempx(16px);

        &:hover {
            background: rgba(black, .1);
        }
    }
}
