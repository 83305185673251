.circle-icon {
    @include unselectable;
    $size: 35px;
    background: $dc-light-blue;
    width: $size;
    height: $size;
    min-width: $size;
    min-height: $size;
    border: 2px solid #FFF;
    font-size: 80%;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    text-transform: uppercase;

    &.medium {
        $size: 50px;
        width: $size;
        height: $size;
        min-width: $size;
        min-height: $size;
        font-size: 100%;
    }

    > img {
        border-radius: 50%;
    }

    &.thick {
        border-width: 2px;
    }

    &.thin {
        border-width: 1px;
    }

    &.bold {
        font-weight: bold;
    }

    &.has-image {
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }

    .status {
        background: $dc-light-grey;
        $size: 10px;
        position: absolute;
        width: $size;
        height: $size;
        top: calc((#{$size} - 10px) * 0.1);
        right: calc((#{$size} - 10px) * 0.1);
        border-radius: 50%;

        &.active {
            background: #2ecc71;
        }

        &.away {
            background: #f39c12;
        }

        &.busy {
            background: #e74c3c;
        }

        .status-text {
            position: absolute;
            $min-width: 50px;
            min-width: $min-width;
            top: calc((#{$size} - 10px) * 0.1);
            right: calc(-#{$min-width} - 9px);
            font-size: rempx(9px);
            font-weight: lighter;
            text-transform: none;
            &.active {
                color: #2ecc71;
            }

            &.away {
                color: #f39c12;
            }

            &.busy {
                color: #e74c3c;
            }
        }
    }

    &.unassigned {
        background: #ececec;
        color: #cecece;
    }
}
