@mixin ipad-content-height {
    /* Portrait and Landscape */
    @media only screen
      and (min-device-width: 768px)
      and (max-device-width: 1024px)
      and (-webkit-min-device-pixel-ratio: 1) {
        height: calc(var(--vh, 1vh) * 100 - #{$search-height});
    }

    /* Portrait and Landscape */
    @media only screen
      and (min-device-width: 834px)
      and (max-device-width: 1112px)
      and (-webkit-min-device-pixel-ratio: 1) {
        height: calc(var(--vh, 1vh) * 100 - #{$search-height});
    }

    /* Portrait and Landscape */
    @media only screen
      and (min-device-width: 1024px)
      and (max-device-width: 1366px)
      and (-webkit-min-device-pixel-ratio: 1) {
        height: calc(var(--vh, 1vh) * 100 - #{$search-height});
    }
}
