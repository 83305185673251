.dc-popover {
    position: relative;
    display: inline-block;
    z-index: 0;

    &-trigger {
        display: block;
        cursor: pointer;
    }

    &-close {
        display: none;
    }

    &-content {
        position: absolute;
        top: calc(100% + 16px);
        opacity: 0;
        transform: translate(0, -50%);
        visibility: hidden;
        padding: rempx(12px);
        background-color: $white;
        border-radius: rempx(8px);
        box-shadow: 0 3px 15px rgba(black, 0.15);
        transition-property: transform, visibility, opacity;
        transition-duration: 100ms;
        min-width: 230px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            transform: translateX(0) translateY(-50%) rotate(45deg);
            width: rempx(16px);
            height: rempx(16px);
            box-shadow: -3px -3px 5px rgba(black, 0.1);
            background-color: $white;
        }
    }

    &-search-input {
        border: 0;
        margin-bottom: rempx(12px);
        font-size: rempx(14px);
        width: 100%;
    }

    &:focus, &:focus-within {
        z-index: 20;

        .dc-popover-content {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
        }
    }

    // modifiers
    &--align-right {
        .dc-popover-content {
            right: 0;

            &:before {
                right: rempx(16px);
            }
        }

    }

    &--align-center {
        .dc-popover-content {
            left: 50%;
            transform: translate(-50%, -50%);

            &:before {
                left: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(45deg);
            }
        }

        &:focus, &:focus-within {
            .dc-popover-content {
                transform: translate(-50%, 0);
            }
        }
    }

    &--align-left {
        .dc-popover-content {
            left: 0;

            &:before {
                left: rempx(16px);
            }
        }
    }
}
