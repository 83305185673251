.auditlogs {
    color: #707070;
    background-color: $white;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: rempx(12);
    width: 100%;
    padding: rempx(12) 0;
    .scrollbar-container {
        max-height: 300px;
        table {
            width: 100%;
            tr {
                border-bottom: 1px solid #f2f2f2;
                th {
                    padding: rempx(16);
                    font-weight: 600;
                    font-size: rempx(16); }
                td {
                    font-size: rempx(14);
                    padding: rempx(8) rempx(16); } }
            tbody {
                tr:last-child {
                    border-bottom: 0; } } }
        @media (max-height: 768px) {
            max-height: 250px; } } }

