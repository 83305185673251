.icon-picker-component {
    max-height: rempx(275px);

    .icon-picker-list {
        background-color: $white;

        .btn {
            opacity: .4;
            padding: 0;
            margin: 0;
            font-size: 31px;
            width: 42px;
            height: 42px;

            &:hover {
                opacity: 1;
            }

            &.selected {
                opacity: .9;
            }
        }
    }
}
