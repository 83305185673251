.quickrespond-popup {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: rempx(62px);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: rgba(32,32,32,0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    overflow: hidden;
    &-content {
        width: 90%;
        max-width: 800px;
        background-color: white;
        border-radius: rempx(19px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 rempx(24);
        &-section {
            display: flex;
            align-items: center;
            padding: rempx(24) 0;
            &.body {
                min-height: rempx(222px);
                padding: 0; } } } }
