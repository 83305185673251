.btn-outline-amazon {
    @include button-outline-variant(#FF9900); }

.btn-outline-ebay {
    @include button-outline-variant(#0064D2); }

.btn-outline-twitter {
    @include button-outline-variant(#1DA1F2); }

.btn-outline-facebook-messenger {
    @include button-outline-variant(#0078FF); }

.btn-outline-fruugo {
    @include button-outline-variant(#2796ff); }
