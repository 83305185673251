.user {
    .circle-icon {
        height: 50px;
        width: 50px;

        .status {
            border: 1px solid $white;
            $size: 15px;
            width: $size;
            height: $size;
            top: calc(-#{$size} / 6);
            right: calc(-#{$size} / 6);
            .status-text {
                  $min-width: 50px;
                  min-width: $min-width;
                  top: calc((#{$size} - 10px) * 0.1);
                  right: calc(-#{$min-width} - 9px); } } }
    .info {
        .name {
            font-weight: bold; }
        .role {
            font-size: 0.7rem; } }
    .account-link {
        font-size: 0.7rem; } }
