.new-auth-card-view {
    width: 100%;
    background-color: $white;
    color: $dark;
    border-top-left-radius: rempx(8);
    border-bottom-left-radius: rempx(8);
    &-scroll {
        padding: rempx(66) rempx(48) 0;
        width: 100%;
        &-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            &-field {
                // padding-bottom: repmx(16)
                &.logo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-bottom: rempx(51);
                }

                &.heading {
                    padding-bottom: rempx(30);
                    .title {
                        font-weight: bold;
                        font-size: rempx(32);
                    }
                    .title-sm {
                        font-weight: bold;
                        display: none;
                    }
                    .subtitle {
                        font-size: rempx(16);
                    }
                }
                &.body {
                    flex: 1;
                }
                // Content
                .auth-signuplink {
                    // flex-grow: 0
                    &-sm {
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-height: 890px) {
        &-scroll {
            padding: rempx(60) rempx(48) 0;
            width: 100%;
            &-content {
                display: flex;
                flex-direction: column;
                &-field {
                    padding-bottom: repmx(12);
                    &.logo {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-bottom: rempx(45);
                    }

                    &.heading {
                        padding-bottom: rempx(26);
                        .title {
                            font-weight: bold;
                            font-size: rempx(30);
                        }
                        .subtitle {
                            font-size: rempx(15);
                        }
                    }
                }
                // Form
                .form-group {
                    margin-bottom: rempx(10);
                    .dc-form-label {
                        font-size: rempx(15);
                        margin-bottom: rempx(6);
                    }
                    .dc-form-input {
                        padding: rempx(21) rempx(32);
                    }
                }
                .dc-form-btn {
                    font-size: rempx(11);
                    padding: rempx(12) rempx(20) !important;
                }
                .dc-form-text-button {
                    font-size: rempx(15);
                }
                // links
                .auth-signuplink {
                    &-md {
                        font-size: rempx(15);
                        .free-trial-link {
                            &-top-text {
                                padding-top: rempx(17);
                                margin-bottom: rempx(4);
                            }
                        }
                    }
                    &-sm {
                        display: none;
                    }
                }
                .auth-email-sent {
                    padding-top: 1rem !important;
                    &-image {
                        margin: 0 !important;
                    }
                    &-text {
                        margin: 0;
                        padding-top: 1.5rem;
                        font-size: rempx(30);
                    }
                }
                .auth-email-sent-message {
                    margin: 0;
                    padding-top: 0;
                    font-size: rempx(15);
                }
                .list-group {
                    .list-group-item {
                        font-size: rempx(15);
                    }
                }
                // Social Auth
                .social-auth {
                    margin-top: 0 !important;
                    margin-bottom: rempx(10);
                }
            }
        }
    }

    @media (max-width: 1200px) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

@mixin small-auth {
    .new-auth-card-view {
        border-top-left-radius: rempx(6);
        border-bottom-left-radius: rempx(6);
        &-scroll {
            padding: rempx(77) rempx(38) 0;
            &-content {
                &-field {
                    padding-bottom: rempx(4);
                    &.logo {
                        padding-bottom: rempx(44);
                    }
                    &.heading {
                        padding-bottom: rempx(4);
                        .title {
                            display: none;
                        }
                        .title-sm {
                            display: inline-block;
                            font-size: rempx(22);
                        }
                        .subtitle {
                            display: none;
                            &-always-show {
                                display: inline-block;
                                font-size: rempx(12);
                            }
                        }
                        .heading-center-sm {
                            text-align: center;
                        }
                    }
                    &.body {
                        form {
                            padding-top: rempx(52);
                        }
                    }
                }
                // Form
                .form-group {
                    margin-bottom: rempx(8);
                    .dc-form-label {
                        font-size: rempx(12);
                        margin-bottom: rempx(6);
                    }
                    .dc-form-input {
                        font-size: rempx(9);
                        padding: rempx(16) rempx(14);
                    }
                    .dc-input-right-link {
                        .dc-dark-link {
                            font-size: rempx(10);
                        }
                    }
                }
                .dc-form-btn {
                    font-size: rempx(11.5) !important;
                    padding: rempx(12) rempx(16) !important;
                }
                .dc-form-text-button {
                    font-size: rempx(11);
                }
                // Social Auth
                .social-auth {
                    margin-top: 0 !important;
                    margin-bottom: rempx(10);
                    p {
                        font-size: rempx(11);
                        white-space: nowrap;
                    }
                    .auth-links {
                        .link-btn {
                            margin-left: rempx(8);
                        }

                        img {
                            width: rempx(32);
                            height: rempx(32);
                        }
                    }
                }
                // add more members
                .auth-add-more-members {
                    font-size: rempx(11);

                    .btn {
                        font-size: rempx(12);

                        &-text {
                            display: none;
                        }
                    }
                }
                // links
                .auth-signuplink {
                    // height: rempx(32)
                    &-md {
                        font-size: rempx(11);
                    }
                    &-sm {
                        display: none;
                        visibility: hidden;
                        opacity: 0;
                    }
                }
                .auth-email-sent {
                    &-text {
                        font-size: rempx(24);
                    }
                }
                .auth-email-sent-message {
                    font-size: rempx(13);
                    a {
                        font-size: rempx(13);
                    }
                }
                .list-group {
                    padding-top: rempx(10);
                    .list-group-item {
                        font-size: rempx(14) !important;
                        margin-bottom: rempx(4) !important;
                        padding: rempx(5);
                        .btn {
                            font-size: rempx(14) !important;
                        }
                    }
                }
                .select-account-title {
                    font-size: rempx(15) !important;
                }
                .dc-popover {
                    input {
                        font-size: rempx(9);
                        padding: rempx(10) rempx(14);
                    }
                }
            }
        }
    }
}

@mixin full-width {
    .col-auth-card {
        max-width: 100% !important;
    }
    .new-auth-card-view {
        width: 100%;
        border-radius: 0;
        &-scroll {
            max-width: 565px;
            margin: auto;
        }
    }
}

@mixin hide-carousel {
    .auth-carousel-container {
        display: none !important;
    }
}

// smaller height
@media (max-height: 830px) {
    @include small-auth;
}

// smaller width
@media (max-width : 834px) {
    @include full-width;
    @include hide-carousel;
}

// all iPad and small screens
@media only screen
    and (max-device-width: 1366px)
    and (max-device-height: 1024px)
    and (-webkit-min-device-pixel-ratio: 1) {
        @include small-auth;
}

@media only screen
    and (max-device-width: 1024px)
    and (max-device-height: 1366px)
    and (-webkit-min-device-pixel-ratio: 1) {
        @include small-auth;
}

// iPad pro
// @media only screen
//     and (max-device-height: 1366px)
//     and (max-device-width: 1024px)
//     and (-webkit-min-device-pixel-ratio: 1) {
//         @include small-auth;
// }

