.icon-preview {
    display: inline-flex;
    width: rempx(32);
    height: rempx(32);
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    flex-shrink: 0;

    &-icon {
        font-size: rempx(17);
    }

    &.large {
        width: rempx(64);
        height: rempx(64);

        .icon-preview-icon {
            font-size: rempx(32);
        }
    }
}
