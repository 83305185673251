.my-account {
    max-width: 70vw;
    margin: auto;
    margin-top: rempx(89);
    &-header {
        &-user {
            background-color: #8ec2d4;
            background-image: url('../../assets/images/profile-cover.png');
            background-position: center;
            background-repeat: no-repeat;
            border-radius: rempx(8);
            min-height: 135px;
            width: 100%;
            //min-width: 70vw
            //min-height: 134px
            //min-width: 1370px
            .name {
                font-size: rempx(23);
                font-weight: 500;
                color: $white; }
            .role {
                font-weight: lighter;
                color: $white; }
            .circle-icon {
                width: 50px;
                height: 50px;
                font-size: rempx(22); } }
        &-upload {
            background-color: $white;
            min-height: rempx(80);
            &-button {
                left: 0;
                right: 0;
                top: -(rempx(60));
                margin: auto;
                font-size: rempx(24);
                background-color: #e1e1e1;
                color: #828282;
                border: 5px solid $white;
                border-radius: 50%;
                width: 120px;
                height: 120px; }
            &-button:focus {
                box-shadow: none; } } }
    &-content {
        margin-left: -1.5rem;
        &-feed {
            padding-right: 1rem;
            &-item {
                margin: rempx(12); } }
        &-menu {
            background-color: $white;
            min-width: 267px;
            border-right: 3px solid #e1e1e1;
            padding-right: rempx(47);
            height: fit-content;
            .menu-item {
                padding-top: rempx(24);
                .menu-link {
                    color: #7b7b7b; }
                .menu-link:hover {
                    color: #5b5b5b; } } } } }
