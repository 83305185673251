.step-carousel {
    // position: relative;
    display: block;
    width: 100%;
    flex-grow: 1;
    overflow: hidden;

    &-navbar {
        // padding-top: rempx(100px);
    }

    &-holder {
        position: relative;
        display: flex;
        height: 100%;
        transition-property: transform;
        transition-duration: 500ms;
        z-index: 0;
    }

    &-pane {
        flex: 1 1 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        flex-direction: column;
        height: calc(100vh - #{rempx(98px)});
        padding-bottom: rempx(98px);
    }

    &-content {
        max-width: calc(100%);
        padding-left: rempx(104);
        padding-right: rempx(104);
        min-width: rempx(375px);
        width: 100%;

        &-fluid {
            flex-grow: 1;
        }

        &-scroll {
            min-height: 100%;
            height: auto !important;
        }

        &-scroll > div {
            max-height: 100%;
        }

        &-middle {
            // margin-top: -98px;
        }
    }

    &-arrow {
        position:absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        width: rempx(66);
        height: rempx(66);
        border: 0;
        box-shadow: 3px 0 15px rgba(black, .16);
        border-radius: 50%;
        background-color: white;
        z-index: 1;

        &.is-disabled {
            opacity: .7;
            pointer-events: none;
        }

        &-left {
            left: rempx(16);
        }

        &-right {
            right: rempx(16);
        }
    }
}
