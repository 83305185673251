h1 {
    color: #33475a;
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1.5rem 0 0.5rem;
}

.worktime-container {
    max-width: rempx(800px);
    margin: rempx(32px) auto 0;
}

.worktime-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1rem;

    td {
        vertical-align: center;
    }

    .manage-selectbox-wrapper {
        max-width: rempx(150px);

        select {
            height: rempx(48px);
            padding: rempx(12px) rempx(18px)
        }
    }
}

@mixin status-spot {
    content: " ";
    position: absolute;
    left: 0;
    width: $status-size;
    height: $status-size;
    border-radius: 50%;
    border: 1px solid $dc-light-blue;
}

@mixin unselectable {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

@mixin expanded-buttons {
    .send-message {
        width: auto;
        border-radius: 50px;
        padding: 0 rempx(16px);

        .button-text {
            display: inline;
        }

        .chevron {
            display: inline;
        }
    }
    .close-message {
        display: inline-flex;
    }
}

.unselectable {
    @include unselectable;
}

@import "components/icons";
@import "components/animations";
.custom-checkbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0.5rem;

    input[type="checkbox"] {
        display: none;
    }

    &-red {
        .cont {
            label {
                background-color: $white !important;
                border: 1px solid $light-gray;
            }
        }

        input:checked + div.cont {
            label {
                @include Fadein;
                animation-duration: 0.2s;
                background-color: #E74C3C !important;
                border-color: #E74C3C;

                i {
                    display: inline;
                }
            }
        }
    }

    .cont {
        label {
            margin: unset;
            $size: 15px;
            width: $size;
            height: $size;
            background-color: #dadada;
            border-radius: 2.5px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: calc(#{$size} / 2);
                color: #fff;
                display: none;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    input:checked + div.cont {
        label {
            @include Fadein;
            animation-duration: 0.2s;
            background-color: $dc-light-blue;

            i {
                display: inline;
            }
        }
    }
}

.sidebar-search-option {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
    }
}

.search-bar {
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(101, 101, 101, 0.2);
    height: $search-height;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    box-shadow: 0 1px 3px 0 rgba(101, 101, 101, 0.2);
    display: flex;
    justify-content: space-between;
    padding: unset;

    .logo,
    .options,
    .search {
        height: 100%;
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 0;

        img {
            min-width: 200px;
        }
    }

    .search {
        flex-grow: 1;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .form-inline {
            height: 100%;
            flex-flow: row;
        }

        .search-input {
            flex-grow: 1;
            background: #fff;
            border: unset;
            height: 100%;
            border-radius: unset;
        }
    }

    .options {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .viewswitch {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 0.2rem 0.5rem;
            padding: 0.2rem;
            cursor: pointer;
            color: $dc-light-grey;
            transition: all 0.2s;

            i {
                font-size: 1.5rem;
            }

            span {
                font-size: 0.75rem;
                display: block;
            }

            &:hover {
                color: $dc-light-blue;
            }

            &.active {
                color: $dc-light-blue;
            }

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        .active-user {
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                color: $offblack;

                .thisuser {
                    font-size: 0.8rem;
                    margin: 0 rempx(16px);
                    font-weight: bold;

                    span {
                        font-weight: normal;
                        display: block;
                        font-size: 0.65rem;
                    }
                }

            }

            .company-popover {
                min-width: 256px;
            }

            .companies {
                display: flex;
                flex-direction: column;
                max-height: 167px;
                padding-right: rempx(16px);
                padding-bottom: 0.7rem;

                .company {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: $dc-black;
                    margin-top: 0.8rem;
                    min-height: 39px;
                    transition-property: opacity;
                    transition-duration: 200ms;

                    &:hover {
                        text-decoration: none;
                        opacity: 0.7;
                    }

                    .name {
                        font-size: rempx(14px);
                        max-width: 144px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .link-account {
                padding: rempx(16px);
                transition-property: opacity;
                transition-duration: 200ms;

                &.red {
                    color: #E74C3C;
                }

                &:hover {
                    text-decoration: none;
                    opacity: 0.7;
                }
            }
        }
    }

    //
}

.sidebar-navigation {
    background: #fff;
    height: calc(100vh - #{$search-height});/* Fallback for browsers that do not support Custom Properties */
    padding: 0;
    top: $search-height;
    width: $nav-size;
    display: flex;
    position: fixed;
    flex-direction: column;
    transition: all 200ms;
    box-shadow: 0 0 3px 0 rgba(101, 101, 101, 0.2);
    z-index: 1;

    @include ipad-content-height;

    .sidebar-trigger {
        display: none;
        top: 50px;
        position: absolute;
        right: 0;
        transform: translateX(100%) rotate(90deg);
        border: 0;
        background-color: $white;
        border: 1px solid #ddd;
        border-bottom: 0;
        box-shadow: 0 -4px 8px rgba(black, .15);
        padding: 0;
        transform-origin: bottom left;
        padding: rempx(4px) rempx(8px);
        font-size: rempx(14px);
        letter-spacing: 1px;
        text-transform: uppercase;
        line-height: 1;
        border-radius: 4px 4px 0 0;
    }

    &.sit-right {
        transform: none;
    }

    @include media-breakpoint-down(lg) {
        transform: translateX(-100%);

        .sidebar-trigger {
            display: block;
        }

        .sidebar-active &,
        &.sidebar-active {
            transform: translateX(0);
        }
    }

    .create-ticket-wrapper {
        .create-ticket {
            border: 0;
            background: $dc-light-blue;
            width: 75%;
            height: 41px;
            font-weight: 300;
            border-bottom-right-radius: 50px;
            border-top-right-radius: 50px;
            color: #fff;
            position: relative;
            transition: all 0.2s;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .paper-plane {
                color: #fff;
                position: absolute;
                right: 18px;
                transition: all 0.2s;
            }

            .chat {
                color: #fff;
                position: absolute;
                right: 18px;
                display: none;
                transition: all 0.2s;
            }

            &:hover {
                width: 85%;
                text-decoration: none;
                // background: $dc-dark-blue;
                .paper-plane {
                    position: absolute;
                    animation-name: flyaway;
                    animation-duration: 10s;
                    animation-timing-function: ease-in;
                    animation-fill-mode: forwards;
                    animation-delay: 0;
                    color: $dc-light-blue;
                }

                .chat {
                    display: block;
                }
            }
        }
    }

    @keyframes flyaway {
        0% {
            top: 10px;
            right: 18px;
        }
        100% {
            top: -10000px;
            right: -10000px;
            transform: scale(-5);
        }
    }

    .filters {
        overflow: auto;
        height: 100%;

        .filter-option {
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }

        .list-group {
            display: flex;
            flex-direction: column;

            .badge {
                font-weight: 300;
                font-size: rempx(14px);
                padding: 0;
                //width: rempx(30px);
                text-align: center;

                &.blank {
                    color: $dc-light-grey;
                    background: unset;
                    border-radius: unset;
                }
            }

            .list-group-title {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h5 {
                    color: $offblack;
                    font-size: rempx(16px);
                    font-weight: 600;
                }

                .toggle-button {
                    cursor: pointer;
                    .icon {
                        transition-property: transform;
                        transition-duration: 100ms;
                        transform: rotate(0deg);

                        &.down {
                            transform: rotate(180deg);
                        }
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            .list-group-content {
                height: 0;
                opacity: 0;
                visibility: hidden;
                transition-property: height, transform, visibility, opacity;
                transition-duration: 100ms;
                opacity: 0;
                transform: translate(0, -1rem);

                &.show {
                    height: 100%;
                    visibility: visible;
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }

            .list-group-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                outline: unset;
                position: relative;
                border: unset;
                font-weight: 300;
                background: unset;
                padding: unset;
                text-align: left;
                margin: 0.2rem 0;
                user-select: none;

                &:before {
                    @include status-spot;
                }

                &-deleted:before {
                    opacity: 0.7;
                }

                p {
                    margin: unset;
                    margin-left: calc(#{$status-size} + 0.5rem);
                    padding: unset;
                    font-size: rempx(14px);
                }

                &.active {
                    font-weight: bold;
                    color: $offblack;

                    &:before {
                        background: $dc-light-blue;
                    }

                    .badge,
                    .blank {
                        color: $offblack;
                        font-weight: bold;
                    }
                }
            }

            .tags {
                .tag {
                    outline: unset;
                    border: unset;
                    background: unset;
                    font-size: 0.875rem;
                    color: $dc-light-grey;
                    padding: 0.5rem;

                    i {
                        color: $dc-light-grey;
                    }
                }
            }
        }
    }

    &.sit-right {
        right: 0;
        padding-top: 30px;

        .agent-icon {
            display: inline-flex;
        }

        .assigned-agent {
            font-weight: bold;
            color: #202020;
            display: inline;
        }

        .tags {
            .tag {
                outline: unset;
                border: unset;
                background: unset;
                font-size: 0.875rem;
                color: $dc-light-grey;
                padding: 0.5rem;

                i {
                    color: $dc-light-grey;
                }
            }
        }

        @mixin inputstyle {
            padding: 0.3rem;
            width: 100%;
            outline: 0;
            border: 0;
            // height: 50px;
            border: 1px solid #d8d8d8;
            border-radius: rempx(20px);
            padding-left: rempx(8px);
            padding-right: rempx(8px);
            background: #fff;
        }

        .input-wrapper {
            position: relative;

            input {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                @include inputstyle;
            }
        }

        .input-wrapper.typeahead-wrapper {
            // Typehead input fix
            .rbt-input.focus {
                box-shadow: none;
                padding-top: 0.375rem;
            }
        }

        .select-wrapper {
            position: relative;
            border: 1px solid #ddd;
            border-radius: 20px;

            &:after {
                content: " ";
                width: 0;
                height: 0;
                position: absolute;
                pointer-events: none;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                top: calc(50% - (8px / 2));
                right: 0.75em;
                border-top: 8px solid black;
                opacity: 0.5;
            }
        }

        .status-icons {
            position: relative;

            &:before {
                @include status-spot;
                z-index: 2;
                background: $dc-light-blue;
                bottom: 0;
                top: 0;
                left: #{$status-size};
                margin-bottom: auto;
                margin-top: auto;
            }

            select {
                position: relative;
                padding-left: calc(#{$status-size} + 1rem);
            }
        }
    }

    .btn-related-orders {
        color: #627688;

        .circle-icon-button {
            width: 34px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #627688;
            border: 1px solid #FFFFFF;
            border-radius: 50%;
            color: white;

            i {
                font-size: rempx(11);
            }
        }
    }
}

.filter-option-item {
    color: $dark;
    font-size: rempx(16px);
    user-select: none;

    &:hover {
        color: $dark;
        text-decoration: none;
    }
}

// START OF WRAPPER
.platform-wrapper {
    margin-left: $nav-size;
    margin-top: $search-height;
    transition-property: margin-right;
    transition-duration: 300ms;

    &.fullmessage {
        margin-left: unset;
        margin-right: $nav-size;
    }

    @include media-breakpoint-down(lg) {
        margin-left: 0;
    }

    @import "~react-resizable/css/styles.css";

    .react-resizable.homepage {
        flex-grow: initial;
    }

    .react-resizable-handle {
        cursor: ew-resize;
        height: 100%;
        padding: 0;
        border-right: 3px solid #e8e8e8;
        background: none;
    }
}

.main-content {
    position: relative;
    padding: unset;
    height: calc(100vh - #{$search-height});/* Fallback for browsers that do not support Custom Properties */
    // min-height: 1500px;

    transition: all 0.2s;
    @include Fadein;

    @include ipad-content-height;
}

@import "components/tickets";
@import "pages/homepage";
.attachment-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .file {
        $size: 46px;
        overflow: hidden;
        cursor: pointer;
        border: unset;
        border-radius: 5px;
        padding: 5px;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: $size;
        position: relative;
        width: $size;

        &:not(:last-child) {
            margin: 0 2px;
        }

        &:not(:first-child) {
            margin: 0 2px;
        }

        background: #e1e1e1;

        i {
            color: transparent;
            z-index: 2;
            position: relative;
            opacity: 0;
            transition-property: color;
            transition-duration: 300ms;
        }

        img {
            // width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            max-width: calc(#{$size} * 4);
            max-height: calc(#{$size} * 4);
            object-fit: cover;
        }

        .opacity {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $dc-dark-grey;
            transition: all 0.2s;
            z-index: 2;
        }

        &:hover {
            .opacity {
                opacity: 0.8;
            }

            i {
                color: #fff;
                opacity: 1;
            }
        }
    }
}

.checkbox {
    position: relative;
    display: inline-flex;
    align-items: center;

    &-element {
        display: none;

        &:checked + .checkbox-handle {
            background-color: $dc-light-blue;

            .checkbox-sign {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &-handle {
        background-color: $light-gray;
        width: rempx(19px);
        height: rempx(19px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: rempx(4px);
        transition-property: background-color;
        transition-duration: 200ms;
    }

    &-sign {
        font-size: rempx(10px);
        color: white;
        line-height: 0;
        opacity: 0;
        visibility: hidden;
        transition-property: opacity, visibility;
        transition-duration: 200ms;
    }

    &-label {
        margin-left: rempx(8px);
        user-select: none;
    }
}

.banner {
    position: relative;

    &-media {
        position: relative;
        z-index: 0;
        width: 100%;
        max-width: 100%;
        border-radius: rempx(10px);
        overflow: hidden;
    }

    &-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        color: $white;
    }
}
