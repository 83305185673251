.popup-dialog {
    // position-absolute top-0 left-0 h-100 w-100 d-flex justify-content-center align-items-center
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;

    &-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $dark;
        opacity: .5;
    }

    &-content {
        // bg-white border-radius-25 shadow-lg
        background-color: $white;
        border-radius: rempx(8);
        box-shadow: 0 0 15px 0 rgba(0,0,0,0.16);
        max-width: 30%;
        min-width: rempx(400px);
        min-height: rempx(250px);
        z-index: 9999;
        padding: rempx(18);
    }
}
