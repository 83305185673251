.color-picker-component {
    max-height: rempx(275px);

    .color-picker-list {
        background-color: $white;

        .btn-color {
            padding: 0;
            margin-right: rempx(4px);
            margin-bottom: rempx(4px);

            .selected-icon {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 100;

                i {
                    font-size: rempx(21);
                }
            }

            &.selected {
                .btn-color-image {
                    opacity: .9;
                }
            }

            &:hover {
                .btn-color-image, .selected-icon {
                    opacity: .8;
                }
            }
        }
    }
}
