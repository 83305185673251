.switch-checkbox {
    $total-width: 50px;
    $total-height: 19px;
    $check-size: 13px;
    $gap: 3px;

    .switch-checkbox__switch {
        cursor: pointer;
        width: rempx($total-width);
        height: rempx($total-height);
        position: relative;
        color: $primary;
        background-color: $controls-bg;
        border-radius: calc(#{$total-height} / 2);
        border: 1px solid;

        .switch-checkbox__checkbox {
            appearance: none;
        }
        .switch-checkbox__check {
            position: absolute;
            width: rempx($check-size);
            height: rempx($check-size);
            top: calc(#{$gap} - 1px);
            bottom: calc(#{$gap} - 1px);
            left: $gap;
            border-radius: calc(#{$check-size} / 2);
            right: calc(#{$total-width} - #{$gap});
            background-color: $primary;
            transition-property: right, left, background-color;
            transition-duration: 300ms;
            transition-timing-function: ease-in-out;
        }

        .switch-checkbox__checkbox:checked + .switch-checkbox__check {
            right: $gap;
            left: calc(#{$total-width} - #{$check-size} - #{$gap} - 1px);
        }
    }

    &--manage {
        $width: 30px;
        $height: 19px;
        $size: 13px;
        $mgap: 3px;

        .switch-checkbox__switch {
            color: $light-gray;
            background-color: $light-gray;
            width: rempx($width);
            height: rempx($height);
            border-radius: calc(#{$height} / 2);
        }

        .switch-checkbox__check {
            background-color: $white !important;
            width: rempx($size);
            height: rempx($size);
            top: calc(#{$mgap} - 1px);
            bottom: calc(#{$mgap} - 1px);
            left: $mgap;
            border-radius: calc(#{$size} / 2);
            right: calc(#{$width} - #{$mgap});
        }

        .switch-checkbox__checkbox:checked + .switch-checkbox__check {
            right: $mgap;
            left: calc(#{$width} - #{$size} - #{$mgap} - 1px) !important;
        }

        &.switch-checkbox--checked .switch-checkbox__switch  {
            color: $primary;
            background-color: $primary;
        }
    }
}
