@mixin Loadin {
    @include Fadein;
    //@for $i from 1 to 100 {
    //// TODO:  Specifiy a max
    //  &:nth-child(#{$i}) { animation-delay: $i * 0.1s; } }
}

$circle-size: 33px;
@mixin round-button {
    height: $circle-size;
    width: $circle-size;
    outline: 0;
    border: 0;
    border-radius: 50%;
    font-size: 0.8rem;
    position: relative;
    transition: all .2s;
    background: $controls-bg;
    i {
        font-size: rempx(14px);
        color: $controls-color;
    }
}

$message-width: 90%;
$message-margin: 12px;
@mixin messages {
    border-radius: 25px;
    // background: #F5F6F6;
    width: $message-width;
    padding: rempx(16px);
    color: $offblack;
    text-align: left;
    transition: all .2s;
    margin: 1.2rem auto;
    position: relative;
    left: 0;
    right: 0;
    @include Loadin;
    .message-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h3 {
            font-size: 0.95rem;
            font-weight: bold;
            margin: unset;
            color: $dc-dark-grey;
        }

        span {
            font-size: 0.825rem;
        }

        .btn-fetch-order {
            width: 34px;
            height: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #627688;
            border: 1px solid #FFFFFF;
            border-radius: 50%;
            color: white;

            i {
                font-size: rempx(11);
            }
        }
    }
    .actual-message {
        p {
            font-size: 0.875rem;
            padding: unset;
            margin: unset;
            word-break: break-word;
        }

        .image-wrapper {
            width: 60%;
            max-width: 500px;
            //// TODO: Decide the max width of a image in a large message
            position: relative;

            img {
                width: 100%;
            }

            .image-controls {
                position: absolute;
                bottom: 5px;
                right: 5px;
                display: none;
                justify-content: flex-end;
                align-items: center;
                transition: all .2s;

                .control {
                    @include round-button;
                    background: $dc-dark-grey;
                    transition: all .2s;

                    i {
                        color: #FFF;
                    }

                    &:hover {
                        background: $dc-light-blue;
                    }
                }
            }

            &:hover {
                .image-controls {
                    display: flex;
                }
            }
        }

        .time {
            font-size: 0.8rem;
            text-align: right;
            color: $dc-light-grey;
        }

        .attatchments-wrapper {
            h3 {
                font-size: 0.8rem;
            }

            // .attatchment-list {
            //   display: flex;
            //   flex-wrap: wrap;
            //   justify-content: flex-start;
            //   .file {
            //     $size: 46px;
            //     overflow:hidden;
            //     cursor: pointer;
            //     border: unset;
            //     border-radius: 5px;
            //     padding: 5px;
            //     font-size: 1.2rem;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     height: $size;
            //     position: relative;
            //     width: $size;
            //     &:not(:last-child) {
            //       margin: 0 2px;
            //     }
            //     &:not(:first-child) {
            //       margin: 0 2px;
            //     }
            //
            //     background: #E1E1E1;
            //     i {
            //       color: #878787;
            //       z-index: 2;
            //       position: relative;
            //       z-index: 3;
            //     }
            //     img {
            //       // width: 100%;
            //       position: absolute;
            //       top: 0;
            //       left: 0;
            //       z-index: 1;
            //       max-width: calc(#{$size} * 4);
            //       max-height: calc(#{$size} * 4);
            //     }
            //     .opacity {
            //       position: absolute;
            //       opacity: 0;
            //       top: 0;
            //       left: 0;
            //       width: 100%;
            //       height: 100%;
            //       background: $dc-dark-grey;
            //       transition: all .2s;
            //       z-index: 2;
            //     }
            //     &:hover {
            //       .opacity {
            //         opacity: 0.8;
            //       }
            //       i {
            //         color: #FFF;
            //       }
            //     }
            //   }
            // }
        }
    }

}

@mixin directional-arrow {
    content: " ";
    height: 0;
    position: absolute;
    top: calc(50% - 10px);
    width: 0;
    z-index: 1;
    border: medium solid #f3f3f3;
    border-width: 10px 10px 10px 0;
    border-color: transparent #f3f3f3 transparent transparent;
}

.message-blank {
    @include messages;
    text-align: center;

    p {
        font-weight: 300;
        margin: unset;
        padding: unset;
        font-size: rempx(14px);
        color: $dc-dark-grey;
        padding-bottom: 0.25rem;
    }

    img {
        max-width: 95%;
    }
}

.message-sent {
    @include messages;
    background: $sent-bg;
    margin-right: $message-margin;

    &:before {
        @include directional-arrow;
        right: -10px;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        border-color: transparent $sent-bg transparent transparent;
    }
}

.message-received {
    @include messages;
    background: $received-bg;
    margin-left: $message-margin;

    &:after {
        @include directional-arrow;
        left: -10px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
        border-color: transparent $received-bg transparent transparent;
    }
}

.internal-note {
    @include messages;
    background: $note-bg;
    width: calc(#{$message-width} + (#{$message-margin} * 2));
}

.message-audit {
    margin: 1.2rem auto;
    padding: rempx(9) rempx(18);
    width: 90%;
    max-width: 570px;
    border-radius: rempx(25px);
    background-color: #fafafa;
    display: flex;
    position: relative;

    .message-audit-message {
        flex-grow: 1;
        text-align: center;
        padding-left: rempx(37px);

        .message-audit-message-date {
            font-size: rempx(11px);
            color: $dark;
        }
    }

    .message-audit-user-icon {
        position: absolute;
        left: rempx(18);
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .circle-icon {
            box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.24);
        }
    }
}

.hints {
    p {
        font-weight: 300;
        margin: unset;
        padding: unset;
    }

    .hint {
        font-size: rempx(14px);
        color: $dc-dark-grey;
        padding-bottom: 0.25rem;
        @include Loadin;
    }

    .activity {
        font-size: 0.85rem;
        color: $dc-light-grey;
        font-weight: 300;
        @include Loadin;
    }
}

.last-read {
    background: $dc-light-blue;
    color: #FFF;
    position: absolute;
    min-width: 180px;
    bottom: calc(#{$controls-height} + 10px);
    font-size: 0.8rem;
    font-weight: 300;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: all .2s;
    width: fit-content;

    &:hover {
        bottom: calc((#{$controls-height} + 10px) + 5px);
    }
}

.controlled-popover-wrapper {
    position: relative;
}

.controlled-popover-content {
    display: none;
    $notch-size: rempx(24px);
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    margin-top: rempx(-10px);
    margin-right: rempx(10px);
    margin-left: rempx(10px);
    margin-bottom: rempx(10px);
    background-color: white;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.16);
    border-radius: rempx(8);
    min-height: rempx(120px);
    min-width: rempx(120px);
    padding: 16px;

    &:before {
        content: '';
        position: absolute;
        bottom: calc(#{-$notch-size} / 2);
        left: $notch-size;
        background-color: white;
        width: $notch-size;
        height: $notch-size;
        transform: rotate(45deg);
        box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.08);

    }

    &.show {
        display: block;
    }

}

.controls-wrapper {
    max-height: 0;
    transition-property: max-height;
    transition-duration: 500ms;

    &.show {
        max-height: 100%;
    }

    &.smaller {
        max-height: 301px;
        .controls {
            textarea.message-textarea {
                max-height: rempx(168px);
            }
        }
    }
}

.controls {
    width: 100%;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: $controls-height;
    display: flex;
    z-index: 2;
    box-shadow: 3px 0 15px rgba(0, 0, 0, 0.08);

    &.single {
        flex-direction: column;

        form {
            flex-grow: 0;
        }

        textarea.message-textarea {
            padding: 5px 5px 5px 10px;
        }
    }

    .canned-responses-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0 rempx(16px);

        .img {
            width: 33px;
            height: 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition-property: background-color, box-shadow;
            transition-duration: 200ms;
            padding: 0.5rem;
            border-radius: 50%;
            background-color: $controls-bg;
            margin-right: 0.5rem;
        }

        .text {
            font-size: rempx(14);
            color: $primary;
            transition-property: color;
            transition-duration: 200ms;
        }

        &:focus {
            outline: none;

            .img {
                background-color: #efefef;
            }

            .text {
                color: $dc-dark-blue;
            }
        }

        &:hover {
            .img {
                background-color: #efefef;
            }

            .text {
                color: $dc-dark-blue;
            }
        }
    }

    .close-message {
        display: inline-flex;
    }

    .send-message {
        @include round-button;
        background: $dc-light-blue;
        color: $white;
        transition: all .2s;
        width: auto;
        border-radius: 50px;
        padding: 0 rempx(16px);

        i {
            color: #FFF;
        }

        i.plane {
            position: relative;
            left: -1px;
        }

        i.chevron {
            color: #FFF;
            margin-left: 0.5rem;
            display: none;
        }

        .button-text {
            color: #FFF;
            display: inline;
            margin: 0 0.5rem;
        }

        &:hover {
            background: $dc-dark-blue;
        }

        &.private-note {
            background-color: #ffab28;
            &:hover {
                background: #e1992a;
            }
        }
    }

    .close-message {
        @include round-button;
        border-radius: 50px;
        width: auto;
        background: unset;
        color: #E74C3C;
        padding: 0 rempx(16px);
        border: 1px solid #E74C3C;
        transition-property: background-color, color;
        transition-duration: 200ms;
        // display: none;
        align-items: center;

        &:hover {
            background-color: #E74C3C;
            color: #FFF;
        }
    }

    .receiver-inputs-wrapper {
        width: 100%;
        min-height: 0;
        // transform: translate(0, -1rem);
        transition-property: height, min-height;
        transition-duration: 200ms;
        overflow: hidden;
        height: 0;

        &.show {
            min-height: rempx(35);
            overflow: visible;
        }

    }

    .receiver-inputs {
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border-color;
        transition-property: height transform opacity;
        transition-duration: 200ms;
        flex-wrap: wrap;
        padding: .5rem;

        &:focus-within {
            z-index: 1;
        }

        &.show {
            min-height: rempx(35);
            height: fit-content;
            opacity: 1;
            transform: translate(0, 0);
            z-index: 1;
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
        }
    }

    .receiver-controls {
        padding: 0.5rem rempx(16px);
        border-bottom: 1px solid $border-color;
        flex-wrap: wrap;

        &-buttons.hide {
            display: none !important;
        }
    }

    .message-controls {
        display: flex;
        align-items: center;
        width: 100%;
        padding: rempx(16) rempx(27);
        transition-property: height;
        transition-duration: .2s;

        &.hide {
            visibility: hidden;
            height: 0;
            padding: 0;
        }
    }

    .control-button {
        color: $dc-dark-blue;
        transition: color 200ms;
        padding: 0 rempx(22);
        cursor: pointer;
        font-size: rempx(12);

        &.border-right {
            border-right: 1px solid $border-color;
        }

        &:focus {
            outline: none;
            color: $dc-light-blue;
        }

        &:hover {
            color: $dc-light-blue;
        }

        .rotate-y-180 {
            transform: rotateY(180deg);
            -webkit-transform: rotateY(180deg);
            -moz-transform: rotateY(180deg);
            -o-transform: rotateY(180deg);
            -ms-transform: rotateY(180deg);
        }
    }
}

.canned-responses {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 0px;
    padding: 0;
    background-color: $white;
    box-shadow: 3px 0 15px 0 rgba(0, 0, 0, 0.16);
    z-index: 2;
    opacity: 0;
    overflow: hidden;
    transition-property: max-height, opacity;
    transition-duration: 300ms;

    &.show {
        overflow: auto;
        padding: rempx(48.8) rempx(25.1);
        opacity: 1;
        max-height: 70%;

        &.single {
            max-height: 340px;
            padding: rempx(20) rempx(48.8) 1px rempx(7);
            position: relative;
            box-shadow: none;
        }

        &.full-height {
            max-height: 85%;
        }
    }

    .close-button {
        color: $primary;
        transition: color 200ms;
        cursor: pointer;

        &:hover {
            color: $dc-dark-blue;
        }

        &:focus {
            outline: none;
            color: $dc-dark-blue;
        }
    }

    .scrollbar-container-canned-responses {
        height: unset;
        overflow: auto;
        margin-top: rempx(16px);
        flex-grow: 1;
    }

    .canned-response-list {
        display: flex;
        flex-direction: column;
        padding: 0 2rem;
        justify-content: flex-start;
        align-items: center;

        .canned-response-item {
            cursor: pointer;
            border: 1px solid #dbdbdb;
            border-radius: rempx(15);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 326px;
            min-height: 92px;
            word-break: break-word;
            margin-top: rempx(15);
            padding: rempx(19);

            &.active {
                border-color: $primary;

                .name {
                    color: $primary;
                }
            }

            .name {
                color: $dark;
                font-size: rempx(14);
                font-weight: bold;
            }

            .description {
                color: $offblack;
                font-size: rempx(10);
            }

            &:focus {
                outline: none;
            }
        }

        &.single {
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            padding: 0;

            .canned-response-item {
                margin: rempx(8);
            }
        }
    }
}

// QUICK RESPONSE
.quickrespond {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    //max-width: 577px;
    min-width: 411px;
    background: #FFF;
    height: 100%;
    position: relative;
    padding: unset;
    box-shadow: 0 0 3px 0 rgba(101, 101, 101, 0.2);
    overflow: hidden;
    height: calc(100vh - #{$search-height}); /* Fallback for browsers that do not support Custom Properties */
    @include ipad-content-height;

    &.mobile {
        // TODO: Code for mobile.
    }

    .empty {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;

        .empty-graphic {
            position: relative;

            h2 {
                font-size: 1.125rem;
                font-weight: bold;
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                top: 12%;
            }

            img {
                max-width: 95%;
            }

            span {
                color: $offblack;
                font-size: 16px;
                font-weight: normal;
                display: block;
                position: relative;
                transition: all .2s; //For fade
            }
        }
    }

    .loaded {
        display: flex;
        flex-direction: column;
        height: 100%;

        .top {
            background: $dc-light-grey;
            color: #fff;
            padding: rempx(8px) rempx(16px);

            p {
                margin: 0;
            }

            .recipients {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h2 {
                    font-size: rempx(16px);
                    font-weight: bold;
                    margin: 0;
                }

                div.item {
                    display: flex;
                    flex-grow: 1;
                    align-items: center;

                    .agent {
                        font-size: rempx(12px);
                    }

                    &:first-child {
                        text-align: left;
                        justify-content: flex-start;
                    }

                    &:last-child {
                        text-align: right;
                        justify-content: flex-end;
                    }
                }
            }

            .subject {
                font-size: rempx(14px);

                .ticket-link {
                    font-size: rempx(10px);

                    a {
                        color: #FFF;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .date {
                font-size: 0.6875rem;
            }

            &.full-message {
                display: flex;
                align-items: center;
                // margin-bottom: calc(72px / 2);
                background-color: $white;
                color: $dark;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);

                .message-spacer {
                    width: 80px;
                    height: 100%;
                }

                .back {
                    margin-right: rempx(16px);

                    a {
                        color: $dark;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    i {
                        font-size: rempx(16px);
                        color: $white;
                        background-color: $dc-dark-grey;
                        width: rempx(32px);
                        height: rempx(32px);
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                    }
                }

                .ticket {
                    position: relative;
                    flex-grow: 1;
                    z-index: 2;
                    top: calc(96px - (72px / 2));
                }

            }

            &.full-message-view {
                background-color: #d0f2ff;
            }
        }

        .bottom {
            background: #FFF;
            box-shadow: 0 0 3px 0 rgba(101, 101, 101, 0.2);
            display: flex;
            justify-content: space-between;

            .expand {
                a {
                    color: $dc-dark-grey;
                    text-decoration: none;
                    font-size: 0.85rem;
                }

                i {
                    position: relative;
                    bottom: -1px;
                }
            }

            .statuses {
                display: flex;
                justify-content: flex-end;
                flex-grow: 1;
                align-items: center;

                .status {
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-size: rempx(14px);
                    margin-left: calc(#{$status-size} + 1rem);
                    justify-content: space-between;

                    &:before {
                        @include status-spot;
                        left: calc((#{$status-size} + .5rem) * -1);
                        background: $dc-light-blue;
                    }

                    &:first-child {
                        margin-right: 6px;
                    }

                    &.option {
                        font-size: 0.875rem;
                        color: $offblack;
                    }
                }

                .spacer {
                    width: 1px;
                    height: 60%;
                    background: #D2D2D2;
                }
            }
        }

        .conversation {
            overflow-y: auto;
            position: relative;
            flex: 1;
            // TODO: Fix this so its a variable height
            // BUG: If set to 100% it will scroll past the page
        }
    }

    .quick-user-assign .dc-popover-content {
        margin-right: 10px;
        margin-top: -10px;
    }

    .quickrespond-resizer {
        cursor: col-resize;
        width: 10px;
        height: 100%;
        position: absolute;
        background-color: transparent;
        transition: all 0.2s;
        z-index: 10;
        margin-top: 152px;

        .quickrespond-resizer-icon {
            display: none;
        }

        &:hover {
            width: 30px;
            background-color: #D2D2D2;
            margin-top: 0;

            .quickrespond-resizer-icon {
                display: block;
            }
        }
    }
}

.control-bar {
    min-height: $controls-height;
    display: flex !important;
    justify-content: flex-end;
    transform: translateY(0);
}
