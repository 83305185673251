body {
    word-break: break-word;
    overflow: hidden; }


.manage-layout {
    height: 100%;
    min-height: 100vh;
    position: relative;
    background-color: $white;
    .left-bottom-img {
        position: absolute;
        bottom: 2rem;
        left: 4rem;
        @media (max-width: 1100px) {
            display: none; } }
    .right-top-img {
        position: absolute;
        top: 3.5rem;
        right: 4.5rem;
        @media (max-width: 1100px) {
            display: none; } }

    .lead-title {
        font-size: rempx(18);
        color: #33475A;
        font-weight: normal; }

    .edit-my-icon-page {
        max-width: 513px;

        .lead-title {
            margin-top: rempx(31);
            margin-bottom: rempx(11); }

        .lead-color-label,.lead-upload-image-label {
            font-size: rempx(16);
            color: #949494;
            font-weight: lighter;
            margin-top: 0;
            margin-bottom: rempx(11); }

        .lead-upload-image-label {
            margin-top: rempx(47);
            margin-bottom: rempx(17); }

        .color-picker-component {
            max-height: 136px;

            .color-picker-list .btn-color {
                flex: 0 0 12.5%; } }

        .cancel-selected-image {
            margin: 0;
            padding: 0;
            border: 0;
            background: none;
            position: relative;
            font-size: 16px;
            color: #000;

            .circle-icon {
                height: 62px;
                width: 62px;

                i.fa-times {
                    position: absolute;
                    top: 25px;
                    left: 0;
                    right: 0;
                    display: none; }

                &:hover {
                    i.fa-times {
                        display: block; }

                    img {
                        opacity: .8; } }

                img {
                    height: 62px;
                    width: 62px;
                    border-radius: 50%;
                    border: 2px solid #FFFFFF;
                    background-color: #E1E1E1;
                    margin-left: 11px; } } }

        .user-information {
            padding-left: rempx(18);
            .user-name {
                font-size: rempx(16);
                font-weight: bold;
                color: #33475A;
                margin-bottom: 1px; }

            .user-email {
                font-size: rempx(15);
                color: #33475A;
                font-weight: normal; } }

        .skip-use-initials {
            background: none;
            border: none;
            color: #25BBF3;
            font-size: rempx(12);
            font-weight: lighter;
            margin-top: 28px; } }

    .change-password-page, .edit-my-details-page {
        max-width: 559px;

        p.title.text-color-dark {
            margin-top: 12px;
            margin-bottom: 11px; } }

    .setup-email-page {
        .manage-field-content {
            @extend .flex-column; } } }

// Manage List
.manage-field {

    &-label {
        margin-bottom: rempx(16px);
        display: block;
        font-size: rempx(16px); }

    &-content {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: rempx(55px);

        & > div {
            width: 100%; } } }

.manage-list-scroll {
    height: auto !important;
    min-height: rempx(225px);
    max-height: rempx(450px);
    @media (max-height: 768px) {
        max-height: rempx(225px); } }

.manage-list {
    max-width: rempx(850px);
    margin: 0 1.5rem;

    .list-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        box-shadow: 3px 0 15px 0 rgba(0,0,0,0.13);
        background-color: $white;
        width: 251px;
        height: 194px;
        border-radius: 8px;
        margin: rempx(14px) rempx(14px);
        .circle-icon {
            height: 69px;
            width: 69px;
            font-size: 1.5rem; }
        .cog-btn {
            color: $light-gray;
            &.dropdown-toggle::after {
                display: none; } }
        .name {
            margin: 0.6rem 0 0 0;
            width: 200px; }
        .role {
            margin: 0.4rem 0 0 0;
            color: #767676; }

        .dropdown-menu {
            left: auto !important;
            right: 0 !important; } }
    .list-add {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $white;
        width: 251px;
        height: 194px;
        border-radius: 8px;
        border: 1px dashed #c1c1c1;
        margin: rempx(14px) rempx(14px);
        .add-btn {
            font-size: 1.8rem;
            background-color: #e1e1e1;
            color: $light-gray;
            width: 69px;
            height: 69px;
            border-radius: 50%;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg); }
        .add-text {
            color: $light-gray;
            margin: 0; } } }

// Manage Input
.manage-input {
    padding: rempx(16) rempx(18);
    width: 100%;
    min-width: 0;
    background-color: transparent;
    border-radius: rempx(55);
    border: 0;
    font-size: rempx(14);
    height: rempx(55);

    &:disabled {
        color: #7d7d7d; }

    &:focus {
        outline: none; }

    &::placeholder {
        color: $light-gray; } }

.copyable-hidden-input {
    position: fixed;
    left: rempx(-9999px); }

.manage-time-input {
    border-radius: rempx(55);
    border: 1px solid $light-gray;
    color: #636363;
    display: flex;
    height: rempx(55);
    font-size: rempx(14);
    &-input {
        appearance: none;
        border: 0;
        padding: 0 rempx(16);
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
        color: inherit;
        flex: 1;
        height: 100%;
        width: 100%;
        max-width: 100px;
        &::placeholder {
            color: $light-gray; } }
    &-selectbox {
        position: relative;
        flex: 1;
        height: 100%;
        &-select {
            height: 100%;
            width: 100%;
            appearance: none;
            border: 0;
            background-color: transparent;
            border-radius: 0;
            color: inherit;
            border-left: 1px solid $light-gray;
            padding-right: rempx(40);
            padding-left: rempx(16); }
        &-icon {
            color: $dark;
            position: absolute;
            height: fit-content;
            top: 50%;
            transform: translateY(-50%);
            right: rempx(18);
            margin: auto;
            pointer-events: none; } } }


.manage-sort-options {
    display: block;
    font-size: rempx(14px);

    &-item {
        display: block;
        color: $dark;
        text-decoration: none !important;
        cursor: pointer;
        margin-bottom: rempx(8px);

        &.is-active {
            color: $primary; } } }

.manage-order-options {
    display: flex;
    font-size: rempx(14px);
    border-top: 1px solid $border-color;
    min-width: 250px;

    &-item {
        display: block;
        color: $dark;
        text-decoration: none !important;
        cursor: pointer;
        text-align: center;
        flex-grow: 1;
        padding: rempx(8px);

        &.is-active {
            color: $primary; } } }

.input-wrapper {
    position: relative;

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: rempx(24px);
        border: 1px solid $light-gray;
        padding: rempx(4px) rempx(8px);
        font-size: rempx(14px); } }

.manage-sort-clear-link {
    display: block;
    color: $primary;
    text-decoration: none !important;
    cursor: pointer;
    text-align: right;
    font-size: rempx(10px); }

.manage-button {
    border: 1px solid #3a8aff;
    color: #3a8aff;
    background-color: $white;
    padding: rempx(16) rempx(8);
    font-size: rempx(14);
    border-radius: rempx(50);
    transition-property: background-color, color;
    transition-duration: 300ms;
    width: 100%;

    &:disabled {
        background-color: #3a8aff;
        border-color: $white;
        color: $white; }

    &:hover {
        background-color: #3a8aff;
        border-color: $white;
        color: $white; } }

.manage-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    border-radius: rempx(50);
    border: 1px solid $light-gray;
    background-color: $white;

    .manage-input {

        &--custom-value {
            color: transparent !important;
            caret-color: $dark;

            &:placeholder-shown,
            &::placeholder {
                color: white !important; } } }

    .manage-input-value-template {
        pointer-events: none;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        overflow: hidden;
        padding: rempx(16) rempx(18);
        width: 100%;
        min-width: 0;
        background-color: transparent;
        border-radius: rempx(55);
        border: 0;
        font-size: rempx(14);
        height: rempx(55);
        white-space: nowrap;
        z-index: 1; }

    .manage-input-append {
        padding: 0 rempx(16);
        font-size: rempx(12);
        white-space: nowrap;
        overflow: hidden;
        flex-shrink: 0;
        border-radius: rempx(55);
        z-index: 2;

        button, input, select {
            border: 0;
            background-color: transparent;

            &:active {
                margin-top: 1px; } } } }

.min-width-293px {
    min-width: 293px; }

// Manage Button
.manage-btn {
    border-radius: 3.1rem;
    font-size: 0.875rem !important;
    padding: 0.6rem 7% !important; }

// Manage Selectbox
.manage-selectbox-wrapper {
    position: relative;
    width: 100%;

    .manage-selectbox {
        appearance: none;
        // min-width: 293px
        font-size: 0.875rem;
        padding: rempx(16) rempx(18px);
        padding-right: 2.5rem;
        background-color: $white;
        color: #636363;
        border: 1px solid $light-gray;
        border-radius: rempx(55);
        width: 100%;
        min-width: 0;
        font-size: rempx(14);
        height: rempx(55);

        &--light-blue {
            background-color: #41bcff;
            border-color: $white;
            color: $white; }

        &--dark-blue {
            background-color: #3a8aff;
            border-color: $white;
            color: $white; }

        .text {
            text-align: left; }

        &:disabled {
            background-color: #FAFAFA; }

        option:hover {
            background-color: $lighter-gray; } }

    .manage-selectbox:focus {
        outline: none; }

    .select-icon {
        pointer-events: none;
        position: absolute;
        right: 1.3rem;
        top: 0;
        bottom: 0;
        margin: auto;
        &--light {
            color: $white; } } }

.auth-autoload-theme {
    .input-wrapper {
        input {
            border-radius: rempx(4);
            color: $dark;
            border-color: $lighter-gray;
            padding: rempx(16) rempx(24);
            font-size: rempx(13);
            &:focus {
                border-color: #9ee1fa; }
            &::placeholder {
                color: $light-gray; } } }
    .dc-popover {
        &-content {
            width: 100%;
 } }            // overflow: scroll
    .timezone-scrollbar {
        max-height: 150px; } }

.manage-autoload-theme {
    .input-wrapper {
        input {
            color: $dark;
            border-color: $light-gray;
            border-radius: rempx(36);
            padding: rempx(16) rempx(18);
            font-size: rempx(14);
            &::placeholder {
                color: $light-gray; } } }
    .dc-popover {
        &-content {
            width: 100%;
 } }            // overflow: scroll
    .timezone-scrollbar {
        max-height: 150px; } }

.manage-badge {
    min-width: 1.5em;
    height: 1.5em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.75em; }

.manage-link-btn {
    font-size: rempx(14px);
    letter-spacing: 0.04rem; }

.manage-form {
    min-width: 560px;
    max-width: 800px; }

.manage-form-scroll {
    width: 100%;
    min-width: 608px;
    max-height: 240px; }

@media (max-width: 590px) {
    .manage-form {
        min-width: 100%; }
    .manage-form-scroll {
        min-width: 100%; } }

.manage-heading {
    text-align: center;

    .title {
        color: $dark;
        font-size: 3.1rem;
        font-weight: lighter;
        margin-top: 0;

        strong {
            font-weight: bold; } }

    .subtitle {
        color: #949494;
        max-width: rempx(450);
        font-weight: lighter; } }

.manage-form-field-title {
    color: $dark;
    font-size: 1.125rem;
    letter-spacing: 0.04rem; }

.manage-textarea-container {
    border: 1px solid $light-gray;
    border-radius: rempx(20);
    padding: rempx(18);

    textarea {
        resize: none;
        width: 100%;
        min-height: 160px;
        flex-grow: 1;
        font-size: rempx(14);
        border: 0;

        &::-webkit-input-placeholder {
            color: $light-gray; }

        &:-moz-placeholder /* Firefox 18- */ {
            color: $light-gray; }

        &::-moz-placeholder /* Firefox 19+ */ {
            color: $light-gray; }

        &:-ms-input-placeholder {
            color: $light-gray; }

        &::placeholder {
            color: $light-gray; }

        &:focus {
            outline: none; } } }

// Modal
.manage-modal-dialog {
    max-width: 833px; }
.manage-modal {
    .modal-content {
        height: 437px;
        border: 1px solid #e1e1e1;
        .modal-field {
            .title {
                font-size: 1.25rem; } } } }

.modal-action-bar {
    border-top: 1px solid $controls-bg;
    .field {
        .btn {
            font-size: 0.875rem !important; }
        .cancel-btn {
            width: rempx(40);
            height: rempx(40);
            background-color: #E74C3C;
            color: $white; }
        .cancel-btn:hover {
            background-color: #D73C2C; }
        .cancel-btn:active {
            background-color: #D73C2C; }
        .cancel-btn:focus {
            box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.25); }
        .save-btn {
            min-height: rempx(40);
            border-radius: 3.1rem;
            padding: 0.6rem 2rem; }
        .unlink-btn:hover {
            color: #E74C3C; } } }

.notch-header-modal {
    .modal-content {
        height: 507px;
        border: none;
        background-color: transparent;
        .inner-modal-content {
            border: rempx(1) solid #e1e1e1;
            background-color: $white;
            border-radius: 0.3rem;
            .scrollbar-container {
                max-height: 347px;
                width: 100%; } }
        .user-icon-header {
            min-height: rempx(70);
            .name {
                font-size: rempx(20); }
            .circle-icon {
                font-size: rempx(24);
                top: rempx(30);
                width: rempx(70);
                height: rempx(70);
                border: rempx(3) solid $white; } } } }

.edit-agent-modal {
    .content {
        max-width: 479px; } }

// Labeled Checkbox
.labeled-checkbox {
    min-width: 20%;
    .question-mark {
        right: 0.75rem !important; }
    .text {
        font-size: 0.875rem; }
    &.theme-primary {
        .text {
            color: $primary; } }
    &.theme-lightgray {
        .custom-checkbox {
            .cont {
                label {
                    background-color: $border-color; } } }
        .text {
            color: $light-gray; } } }

.question-mark-parent {
    position: relative;
    display: inline-block;

    .question-mark {
        position: absolute;
        right: -1rem;
        top: -0.7rem; } }

.background-invites-sent {
    background-image: url('../../assets/images/invites-sent.svg');
    background-position: center;
    background-repeat: no-repeat;
    min-width: 495px;
    min-height: 305px; }

.background-ebay-success {
    background-image: url('../../assets/images/ebay-channel-success.svg');
    background-position: top;
    background-repeat: no-repeat;
    min-width: 495px;
    min-height: 305px;
    padding-top: 50px; }


.mt-minus-2rem {
    margin-top: -2rem !important; }

.btn-manage {
    font-size: 0.875rem !important;

    &.cancel-btn {
        width: rempx(40);
        height: rempx(40);
        background-color: #E74C3C;
        color: $white; }

    &.cancel-btn:focus {
        box-shadow: 0 0 0 0.2rem rgba(231, 76, 60, 0.25); }

    &.archive-btn {
        width: rempx(40);
        height: rempx(40);
        background-color: $light-gray;
        color: $white; }

    &.archive-btn:focus {
        box-shadow: 0 0 0 0.2rem rgba(76, 76, 76, 0.25); }


    &.save-btn {
        min-height: rempx(40);
        border-radius: 3.1rem;
        padding: 0.6rem 2rem; }
    &.save-btn:disabled {
        cursor: not-allowed; }
    &.danger-link-btn {
        color: #E74C3C;
        &:hover {
            opacity: 0.85; }
        &:focus {
            box-shadow: none; } }
    &.link-btn {
        color: $primary;
        &:hover {
            opacity: 0.85;
            text-decoration: none; }
        &.text-xsmall {
            font-size: rempx(12) !important; } } }


.btn-collapse {
    font-size: 1rem;
    padding: 0;
    &:focus {
        box-shadow: none; }
    &.collapsed {
        .icon {
            transform: rotate(0deg); } }
    .icon {
        transform: rotate(180deg);
        transition-property: transform;
        transition-duration: 200ms; } }

.btn-circle {
    border: 1px solid $light-gray;
    width: rempx(55);
    height: rempx(55);

    &:hover {
        background-color: darken(white, 5%); }

    &:focus {
        background-color: darken(white, 10%); } }

.btn-icon {
    &:focus {
        box-shadow: none; } }

.float-bottom-container {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: rempx(22) rempx(63);
    width: 100%;
    &--shadow {
        box-shadow: 0 0 3px 0 rgba(0,0,0,0.16); } }

.relative-bottom-container {
    padding:  rempx(22) rempx(63);
    width: 100%; }

.form-control:focus {
    outline: none;
    box-shadow: none; }


.user-icon-group {
    display: inline-flex;
    flex-direction: row-reverse;

    &-item {
        box-shadow: 0 0 8px rgba($black, .2);
        font-size: rempx(12px);
        font-weight: normal;

        &:not(:last-child) {
            margin-left: rempx(-16px);

            &:hover {
                z-index: 1; } } } }

.manage-focus-field {
    border-radius: rempx(10);
    border: 1px solid $primary;
    padding: 1rem; }

.ebay-channel-logo {
    $size: 84px;
    width: $size !important;
    height: $size;
    background-color: #74B9FF;
    position: relative;
    color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.3rem;
    .isactive-icon {
        font-size: 0.7rem;
        $csize: 32px;
        width: $csize;
        height: $csize;
        color: $white;
        position: absolute;
        top: 0;
        right: 0;
        border: 3px solid $white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center; } }

.ebay-provider-status {
    border-radius: rempx(5);
    font-size: rempx(12);
    color: $white;
    padding: rempx(2) rempx(7);
    font-weight: lighter; }

.ebay-mail-status {
    color: $light-gray;
    font-size: rempx(12);
    font-weight: lighter; }

.sla-targets-table {
    .manage-time-input {
        height: rempx(48); }
    .manage-selectbox {
        height: rempx(48);
        padding-top: 0;
        padding-bottom: 0; } }
