.my-account-content {
    .masonry-column {
        @media (min-width: 1100px) {
            max-width: 50% !important; } } }

.activity {
    background-color: $white;
    border-radius: rempx(8);
    box-shadow: 3px 0 15px 0 rgba(0,0,0,0.13);
    max-width: 500px;
    .header {
        .circle-icon {
            border: none;
            width: rempx(46);
            height: rempx(46);
            font-size: 100%; }
        .date {
            color: #9c9c9c; }
        .more-btn:focus {
            box-shadow: none; }
        .more-btn:hover {
            .more-icon {
                transition: color 300ms;
                color: $dark-gray !important; } } } }

.activity.private-note {
    background-color: #FDEBCF; }

.activity-btn {
    border-radius: rempx(50);
    min-height: rempx(37);
    width: fit-content;
    min-width: 140px; }

.activity-text {
    max-height: rempx(72);
    overflow: hidden; }

.activity-ticket-reply {
    border-radius: rempx(5);
    background-color: $received-bg;
    max-height: 220px;
    overflow: hidden; }

.activity-attachment-title {
    font-size: rempx(11); }

.activity-ticket-info {
    .info {
        .circle-icon {
            width: rempx(35);
            height: rempx(35); } }
    .status {
        position: relative;
        height: fit-content;
        .ticket-new-status {
            color: #212326; }
        .ticket-new-status::before {
            content: ' ';
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: rempx(-15);
            width: rempx(10);
            height: rempx(10);
            border-radius: 50%;
            border: 1px solid #25BBF3; } }
    .color-circle-cnt {
        display: flex;
        height: fit-content;
        justify-content: center;
        align-items: center;
        word-break: keep-all;
        .color-circle {
            background-color: #25BBF3;
            border-radius: 50%;
            $size: rempx(10);
            width: $size;
            height: $size; } } }

.activity-information {
    &-image {
        @media (max-width: 1541px) {
            display: none !important; } } }
