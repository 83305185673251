$base-font-size: 16px;
$font-family-sans-serif: 'Poppins', sans-serif;

$dark-gray: #a7a7a7;
$darker-gray: #949494;
$light-gray: #c4c4c4;
$lighter-gray: #ebebeb;
$lightest-gray: #f8f8f8;
$border-color: #e1e1e1;
$primary: #25BBF3;
$dark: #33475A;
$body-color: $dark;
$bg-auth: #324759;
$enable-validation-icons: false;
$dc-black: #2c2c2c;
$dc-slate-gray: #6E8293;
$dc-gray: #9a9a9a;

// Helpdesk variables
$body-bg: #F6F6F6;
$celebration-bg: #55EFC4;
$dc-light-blue: #25BBF3;
$dc-lighter-blue: #52C9F5;
$dc-dark-blue: #3596D4;
$dc-light-grey: #627688;
$offblack: #202020;
$dc-dark-grey: #33475A;
$silver-plan: #004964;
$gold-plan: #f1c40f;
$platinum-plan: #25bbf3;
$tooltip-color: #FFF;
$tooltip-bg: $dc-light-blue;
$tooltip-arrow-color: $dc-light-blue;
$secondary: $dc-light-grey;
$nav-size: 258px;
$search-height: 62px;
$sent-bg: #D3F1FD;
$received-bg: #F5F6F6;
$note-bg: #FDeBcF;
$controls-height: 60px;
$controls-bg: #F3F3F3;
$controls-color: #B8B8B8;
$status-size: 10px;
$simplebar-width: 15px;
$datepicker-disabled: #DCF5FE;
$datepicker-disabled-text: #FFF;
$success: #38CE77;
$bulk-actions-height: 70px;
$dc-cancel-red: #FF7070;
$dc-cancel-red-hover: #E34F4F;

// More variables about Bootstrap 4 is available in
// node_modules/bootstrap/scss/_variables.scss

// Status colors
$status-colors: (
    "open": #25BBF3,
    "awaiting_reply": #F39C12,
    "resolved": #2ECC71,
    "archive": #C4C4C4,
    "spam": #eb2250,
);

// Priority colors
$priority-colors: (
    "urgent": #E74C3C,
    "high": #F39C12,
    "medium": #2ECC71,
    "low": #6AD0F6
);

// Despatch Cloud colors
$dc-colors: (
    "primary": $primary,
    "sea_green": #4BF0C3,//
    "electric_blue": #7CECED,//
    "french_sky_blue": #71B8FF,//
    "pale_violet": #A298FF,//
    "flavescent": #FFEBA3,//
    "french_pink": #FF77A8,//
    "burnt_sienna": #E37050,//
    "gunmetal": #2D3436,//
    "mountain_meadow": #00B994,//
    "edge_blue": #0082E6,//
    "majorelle_blue": #6C56EB,//
    "melon": #FCB19E,//
    "slate_gray": $dc-slate-gray,
);

$active-colors: (
    "active": #2ECC71,
    "inactive": #E74C3C
);

$log-type-colors: (
    "created": #2ECC71,
    "updated": #25BBF3,
    "deleted": #E74C3C
);
