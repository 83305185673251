.respond-attatchments {
    display: flex;
    align-items: center;
    $circle-size: 33px;

    .expand-options {
        height: $circle-size;
        width: $circle-size;
        outline: 0;
        border: 0;
        border-radius: 50%;
        font-size: 0.8rem;
        position: relative;
        transition: all .2s;
        background: $controls-bg;
        i {
            font-size: rempx(14px);
            color: $controls-color;
        }
        transition-property: transform, background-color;
        transition-duration: .2s;

        &:hover {
            background-color: #efefef;
        }

        &:focus {
            background-color: #efefef;
        }

        &.active {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .extra-options {
        background: $controls-bg;
        padding: 5px;
        position: relative;
        border-radius: 50px;
        margin-left: 10px;

        button {
            color: $dc-light-blue;
            background: unset;
            border: unset;

            i {
                color: $dc-light-blue;
            }
        }

        &:before {
            content: " ";
            height: 0;
            position: absolute;
            top: calc(50% - 10px);
            width: 0;
            z-index: 1;
            border: medium solid #f3f3f3;
            border-width: 10px 10px 10px 0;
            border-color: transparent #f3f3f3 transparent transparent;
            $size: 6px;
            left: calc(#{$size} * -1);
            top: calc(50% - #{$size});
            border-width: $size $size $size 0;
            border-color: transparent $controls-bg transparent transparent;
        }

        &.attachments {
            .attachment {
                .icon-delete {
                    display: none;
                    color: $light-gray;
                }

                .icon-file {
                    display: initial;
                }

                &:hover {
                    .icon-delete {
                        display: initial;
                    }

                    .icon-file {
                        display: none;
                    }
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
}
