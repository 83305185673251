.message-textarea {
    resize: none;
    min-height: rempx(168px);
    flex-grow: 1;
    border: 0;
    font-size: rempx(14px);
    max-height: 100%;
    transition-property: max-height;
    transition-duration: 500ms;

    &::-webkit-input-placeholder {
        color: $light-gray;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: $light-gray;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: $light-gray;
    }

    &:-ms-input-placeholder {
        color: $light-gray;
    }

    &::placeholder {
        color: $light-gray;
    }

    &:focus {
        outline: none;
    }
}
