.color-list-item {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: rempx(14px);
  cursor: pointer;

  &.active:before,
  &:hover:before {
    background-color: currentColor;
  }
  
  &:before {
    content: '';
    color: currentColor;
    flex-shrink: 0;
    width: rempx(10px);
    height: rempx(10px);
    border-radius: 50%;
    margin-right: rempx(10px);
    border: 1px solid;
  }

  &-label {
    color: $dark;
    flex-grow: 1;
    text-align: left;
    font-weight: 300;
  }
}