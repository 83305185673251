.checkbox-border-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 326px;
    min-height: 92px;
    word-break: break-word;
    border-radius: rempx(15);
    padding: rempx(20);
    height: 100%;
    background-color: white;

    &:focus {
        outline: none; } }


.no-outline-text-btn {
    outline: none !important;
    box-shadow: none !important;
    opacity: 1;
    transition-property: text-decoration color;
    transition-duration: 200ms;
    &:hover {
        text-decoration: underline; } }

.shadow-box-container {
    box-shadow: 3px 0 15px 0 rgba(0,0,0,0.13);
    border-radius: rempx(8);
    padding: 1rem; }


.integration-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 810px; }

.integration-name {
    max-width: 110px; }

.integration-box {
    width: 118px;
    height: 118px;
    display: flex;
    justify-content: center;
    align-items: center; }
