.customer-orders-sidebar {
    $animation-duration: 500ms;
    position: relative;
    display: block;
    max-width: 0;
    transition-property: max-width;
    transition-duration: $animation-duration;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0 0 3px 0 rgba(101, 101, 101, 0.2);
    z-index: 2;

    &-absolute {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }

    &.customer-orders-sidebar-active,
    &-active & {
        max-width: rempx(360px);

        .customer-orders-sidebar-inner {
            transform: translateX(0);
        }
    }

    &-inner {
        min-width: rempx(260px);
        height: calc(100vh - 62px);/* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100 - #{$search-height});
        transform: translateX(100%);
        transition-property: transform;
        transition-duration: $animation-duration;
    }

    &-close {
        position: absolute;
        top: rempx(8px);
        left: rempx(8px);
        border: 0;
        background-color: transparent;
        color: $white;
    }

    &-customer {
        position: relative;
        height: rempx(120px); // Hard coded fnow
        background-color: $dc-slate-gray;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-name {
            font-size: rempx(16px);

        }

        &-email {
            font-size: rempx(12px);
        }
    }

    &-count {
        font-size: rempx(10px);
        font-weight: 300;
        text-align: center;
        padding: rempx(10px);
    }

    pre {
        white-space: pre-wrap;
        font-size: rempx(10px);
        letter-spacing: 1px;
    }
}

.cos-order {

    &-header {
        display: flex;
        align-items: center;
        padding: rempx(16px) rempx(20px);
    }

    &-meta {
        // empty.
    }

    &-date {
        color: $dark-gray;
        font-size: rempx(12px);
        font-weight: 300;
    }

    &-id {
        color: $black;
        font-weight: bold;
        font-size: rempx(16px);
    }

    &-ref {
        color: $primary;
        font-size: rempx(12px);
        font-weight: 300;
    }

    &-options {
        margin-left: auto;
    }

    &-collapse-button {
        border: 0;
        background-color: transparent;
    }

    &-content {
        padding: 0 rempx(20px);
        font-size: rempx(12px);
        max-height: 0;
        overflow: hidden;
        transition-property: max-height;
        transition-timing-function: linear;
        transition-duration: 500ms;

        &-active {
            max-height: 70vh;
            overflow: auto;
        }
    }

    &-row {
        display: flex;
        padding: rempx(4px);
        font-size: rempx(12px);
        align-items: center;
    }

    &-row-icon {
        flex-shrink: 0;
        flex-grow: 0;
        width: rempx(20px);
    }

    &-row-label {
        flex-shrink: 0;
        flex-grow: 1;
    }

    &-row-value {
        margin-left: auto;
        text-align: right;
        flex-grow: 1;
    }

    &-row-quantity {
        margin-right: 8px;
        text-align: left;
    }

    &-badge {
        display: inline-flex;
        width: 1.5em;
        height: 1.5em;
        align-items: center;
        justify-content: center;
        border-radius: 1.5em;
        box-shadow: 0 0 8px rgba(black, .15);
    }
}
