@mixin filter-option($color, $border-color: $color) {
    &::before {
        border-color: $border-color !important;
    }

    &.active::before {
        background-color: $color !important;
    }
}

@mixin text-color($color) {
    & {
        color: $color !important;
    }
}

@mixin background-color($background-color: $color) {
    & {
        background-color: $background-color !important;
    }
}

@each $color, $value in $status-colors {
    .filter-option-#{$color} {
        @include filter-option($value);
    }

    .text-color-#{$color} {
        @include text-color($value);
    }

    .background-color-#{$color} {
        @include background-color($value);
    }
}

@each $color, $value in $priority-colors {
    .filter-option-#{$color} {
        @include filter-option($value);
    }

    .background-color-#{$color} {
        @include background-color($value);
    }
}

@each $color, $value in $dc-colors {
    .filter-option-#{$color} {
        @include filter-option($value);
    }
}

@each $color, $value in $log-type-colors {
    .text-color-#{$color} {
        @include text-color($value);
    }

    .background-color-#{$color} {
        @include background-color($value);
    }
}
