#nprogress {
    .bar {
        background-color: $primary; }
    .spinner-icon {
        border-top-color: $primary;
        border-left-color: $primary; } }

.Toastify {
    .Toastify__toast {
        @extend .rounded;
        @extend .font-weight-light;
        .Toastify__toast-body {
            font-family: $font-family-sans-serif; } } }

.progress-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background-color: rgba(0,0,0,0.5)
    z-index: 1000;
    cursor: progress;

    @include media-breakpoint-down(sm);
    .auth-body {
        background-color: $white !important; }
    .auth-container {
        position: initial;
        display: flex !important;
        justify-content: space-between;
        align-items: center !important;
        background-color: $white !important;
        flex-direction: column !important;

        //&.position-absolute
        //    position: initial !important

        .auth-container-wrapper {
            .col-auth-card {
                max-width: 100% !important;
                width: 100%; } }

        .footer {
            position: initial;
            background-color: $bg-auth;

            @include media-breakpoint-down(lg); } }
    .auth-container {
        .card-auth {
            border: 0;
            border-radius: 0;
            box-shadow: none !important; } } }

@media (max-height: 675px) {
    .auth-container {
        &.position-absolute {
            position: initial !important; }

        .auth-container-wrapper {
            margin-top: 1rem !important;
            margin-bottom: 0 !important;
            align-items: flex-start !important;

            .card-auth {
                .scrollbar-container {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important; }

                .card-img-top {
                    padding-bottom: 0 !important; } } } } }

.min-h-100vh {
    min-height: 100vh; }
.min-h-90vh {
    min-height: 90vh; }

.auth-body {
    background-color: $bg-auth; }

.auth-container {
    background-color: $bg-auth;
    padding-right: 0;
    flex: 1;
    .col-auth-image {
        height: 90vh;
        min-height: 365px;
        overflow: hidden;
        width: 100%;
        img {
            max-width: 640px !important; } }

    .col-auth-card {
        max-width: 565px;
        width: 100%;
        @media (min-width: 1200px) {
            max-width: calc(100%/3.4);
            min-width: 450px; } } }

/* Carousel */
.auth-carousel-container {
    height: 90vh !important;
    overflow-y: auto;
    .carousel {
        height: 100% !important; }
    .carousel-inner {
        height: 100%;
        width: 100%;
        img {
            height: 100% !important; } }
    .carousel-caption {
        text-align: left;
        bottom: 10%;
        font-size: rempx(14);
        h1 {
            font-size: rempx(58);
            font-weight: bold; }
        @media (max-height: 860px) {
            font-size: rempx(13);
            h1 {
                font-size: rempx(50); } }
        @media (max-height: 830px) {
            font-size: rempz(12);
            h1 {
                font-size: rempx(41); } } }

    .carousel-indicators {
        padding-bottom: 1rem;
        justify-content: flex-start;
        li {
            background-color: $white;
            opacity: 1;
            width: 53px;
            height: 2px;
            margin-right: 5px;
            margin-left: 5px;
            border: 1px solid $white;
            border-radius: 3px; }
        .active {
            background-color: $primary;
            border-color: $primary; } } }

.pb-38rem {
    padding-bottom: 3.8rem !important; }

.pt-1rem {
    padding-top: 1rem !important; }

.right-border-no-radius {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }

/* Font style classes */
.bold-font {
    font-weight: bold; }

.light-font {
    font-weight: lighter;
    font-size: 14px; }

.text-color-dark {
    color: $dark; }

.text-color-light-gray {
    color: $light-gray; }

/* Form */
.dc-form-input {
    font-size: 0.81rem;
    padding: 1.5rem;
    border: 1px solid $lighter-gray;
    color: $dark; }

.dc-form-input::placeholder {
    color: $light-gray; }

.dc-form-label {
    color: $dark; }

.dc-form-btn {
    padding: 1.21rem 2.5rem !important;
    font-size: 0.81rem !important;
    white-space: nowrap;
    //padding: 0.6rem !important
 }    //margin-top: 1.5rem

.dc-input-with-right-link {
    padding-right: 132px; }

.dc-input-right-link {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    z-index: 99;
    .dc-dark-link {
        font-size: 0.75rem; } }

.dc-dark-link {
    color: $dark;
    text-decoration: none; }

.dc-gray-link {
    color: #b0b0b0;
    text-decoration: none; }

.dc-dark-link:hover {
    color: $dark;
    text-decoration: none; }

.contains-sign-up-link {
    .card-body {
        display: flex;
        flex-direction: column;
        .sign-up-link {
            flex: 1;
            justify-content: center;
            display: flex;
            @media (max-height: 830px) {
                padding-top: .5rem !important;
                padding-bottom: .5rem !important; } } } }

.social-auth {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        color: $dark;
        margin: auto;
        margin-left: 0;
        font-size: rempx(14); }

    .auth-links {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .link-btn {
            background: none;
            border: none;
            outline: none;
            padding: 0;
            margin-left: 1rem;

            img {
                width: rempx(40);
                height: rempx(40); } } }

    @media (max-height: 830px) {
        margin-bottom: 1rem; } }

.cb-p-10-25 {
    .card-body {
        padding: 10px 25px 0px 25px !important;
        .card-title {
            text-align: center; }
        .subtitle {
            text-align: center; } } }

.auth-signuplink {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rempx(16);
    // padding: rempx(24) 0 0
    margin: auto;
    // flex: 1
    // height: 100%
    .free-trial-link {
        background: url("../assets/images/free-trial-bg.svg") #fff top center no-repeat;
        width: 280px;
        &-top-text {
            padding-top: 1.7rem;
            @media (max-height: 830px) {
                padding-top: .9rem;
                margin-bottom: .25rem; } } } }

.fill-parent {
    width: 100%;
    height: 100%; }

/* Footer */
.footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    color: $white;
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 0.625rem;
    padding: 0.5rem;
    //background-color: $bg-auth

    p {
        margin: 0; } }
.messages-page {
    min-height: 100vh !important;
    .main-row {
        min-height: 100vh !important;
        .col {
            min-height: 100vh !important;
            .sidebar {
                background-color: #fff; }
            .ticket-list {
                width: 100%; }
            .ticket-detail {
                background-color: #fff;
                box-shadow: 0 4px 3px 1px rgba(0, 0, 0, 0.1);
                border: none; } } }
    .messages-navbar {
        z-index: 1;
        box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.1);
        background: #fff;
        .search {
            border: none;
            .input {
                border: none !important;
                outline: 0px !important;
                -webkit-appearance: none;
                box-shadow: none !important; }
            .icon {
                background-color: transparent;
                border: none; } } } }

.list-group-reverse-flush {
    .list-group-item {
        @extend .border-top-0; }

    .list-group-item:last-child {
        @extend .border-bottom;
        @extend .mb-5; } }

.cursor-initial {
    cursor: initial !important; }

.h-90 {
    height: 90% !important; }

.main-menu .messages .quickrespond .loaded .conversation, .message-page .messages .quickrespond .loaded .conversation {
    padding-bottom: 0 !important; }

.modal-dialog-attachment {
    background-color: #202020;

    .modal-dialog {
        @extend .container-fluid;
        max-width: 100%;

        .modal-content {
            @extend .border-0;
            @extend .bg-transparent;

            .file-close-button {
                font-size: 12px; }

            .file-info-extra {
                font-size: 12px; }

            .file-info {
                @extend .text-white;

                .file-big-icon {
                    margin-bottom: 36px; }

                .file-info-title {
                    font-size: 32px;
                    font-weight: bold;
                    margin-bottom: 8px; }

                .file-info-text {
                    font-size: 16px;
                    margin-bottom: 26px; }

                .file-info-name {
                    font-size: 12px;
                    margin-bottom: 31px; }

                .file-info-button {
                    min-width: 182px;
                    border-radius: 1rem;
                    font-size: 14px;
                    margin-bottom: 47px; }

                .file-info-extra {
                    text-align: center; }

                .file-close-button {
                    margin-top: 90px !important; } } } } }

.main-content.medium-view {
    .ticket-ref {
        @extend .col-auto; } }

.fullmessage {
    .send-message-button-dropdown {
        .dropdown-menu {
            right: 0;
            left: auto !important; } } }

.send-message-button-dropdown {
    .send-message {
        padding: initial;

        &::after {
            display: none; } } }

.circle-icon-button {
    height: 33px;
    width: 33px;
    outline: 0;
    border: 0;
    border-radius: 50%;
    font-size: rempx(16);
    position: relative;
    transition: background-color .2s;
    background-color: $controls-bg;
    color: $dark-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: #efefef;
        color: $dark-gray;
        text-decoration: none; } }


.btn-link:hover {
    text-decoration: none; }

.font-18 {
    font-size: rempx(18); }

// Scrollbar

.scrollbar-container.black {
    .ps__rail-y {
        width: rempx(6);
        .ps__thumb-y {
            background-color: rgba(32,32,32,0.85);
            width: rempx(3); } }
    .ps__rail-y:hover {
        background-color: rgba(225,225,225, 0.25);
        opacity: 50%; }
    .ps__rail-x {
        height: rempx(6);
        .ps__thumb-x {
            background-color: rgba(32,32,32,0.85);
            height: rempx(3); } }
    .ps__rail-x:hover {
        background-color: rgba(225,225,225, 0.25);
        opacity: 50%; } }

.scrollbar-container.slim-gray {
    .ps__rail-y {
        width: rempx(6);
        .ps__thumb-y {
            background-color: rgba(162,162,162,0.85);
            width: rempx(3); } }
    .ps__rail-y:hover {
        background-color: rgba(225,225,225, 0.25);
        opacity: 50%; }
    .ps__rail-x {
        height: rempx(6);
        .ps__thumb-x {
            background-color: rgba(162,162,162,0.85);
            height: rempx(3); } }
    .ps__rail-x:hover {
        background-color: rgba(225,225,225, 0.25);
        opacity: 50%; } }

.scrollbar-container.z-index-1 {
    .ps__rail-y {
        z-index: 1; }
    .ps__rail-x {
        z-index: 1; } }


input:focus, :focus {
    outline: 0 !important; }

.btn:focus {
    box-shadow: none; }

.flex-shrink-none {
    flex-shrink: initial !important; }


.auth-height {
    height: 90vh;
    max-height: 850px; }
