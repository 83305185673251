.navigation-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition-duration: 300ms;
  transition-property: visibility, opacity;

  &-active {
    opacity: 1;
    visibility: visible;

    .navigation-menu-window {
      transform: translateX(0);
    }
    .navigation-menu-backdrop {
      opacity: 1;
      visibility: visible;
    }
  }

  &-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, .15);
    z-index: 0;
    visibility: hidden;
    opacity: 0;
    transition-duration: 300ms;
    transition-property: visibility, opacity;
  }

  &-window {
    position: relative;
    width: 100%;
    max-width: rempx(1020px);
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: $white;
    box-shadow: 0 0 8px rgba(black, .15);
    transform: translateX(-100%);
    transition-duration: 300ms;
    transition-property: transform;
    height: 100%;
    // padding-bottom: rempx(50px);
  }

  &-heading {
    position: relative;
    display: flex;
    padding: 0 rempx(16px);
    flex-shrink: 0;
  }

  &-user {
    margin-left: auto;
  }

  &-close {
    position: absolute;
    top: rempx(-2px);
    left: rempx(-4px);
    border: 0;
    padding: 0 rempx(8px);
    background-color: transparent;
    margin-top: rempx(20px);
    margin-left: rempx(24px);
    font-size: rempx(20px);
    color: $light-gray;
    height: rempx(30px)
  }

  &-body {
    flex-grow: 1;
    min-height: 0;
  }

  &-inner {
    padding-right: rempx(110px);
    padding-left: rempx(110px);
  }

  &-section-title {
    font-weight: bold;
    font-size: rempx(16px);
    // margin-top: rempx(24px);
    margin-bottom: rempx(24px);
  }

  &-settings-section {
    margin-top: rempx(16px);
  }

  &-helpdesk {
    margin-top: rempx(16px);

    &-image {
      width: 100%;
    }
    &-link {
      text-align: right;
    }

    @media screen and (max-height: 768px) {
      & .navigation-menu-section-title {
        display: none;
      }

      &-image {
        display: none;
      }

      &-link {
        margin-top: rempx(16px);
        text-align: left;
      }
    }
  }

  &-summary {
    &-title {
      font-size: rempx(30px);
      margin-bottom: rempx(24px);
      margin-top: 0;
    }
    &-subtitle {
      margin-top: 0;
      margin-bottom: 0;
      font-size: rempx(20px);
    }
  }

  &-statistics {
    &-banner {
      position: relative;

      &-image {
        width: 100%;
      }
      &-coming-soon {
        position: absolute;
        right: rempx(16px);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &-content {
      position: absolute;
      left: rempx(32px);
      top: 50%;
      transform: translateY(-50%);
    }

    &-title {
      font-size: rempx(16px);
      font-weight: bold;
      margin-bottom: rempx(8px);
    }
    &-subtitle {
      font-size: rempx(14px);
      margin: 0;
    }
  }

  &-settings__container {
    position: relative;
    padding: 0 20px 10px;

    &--no-scroll {
        padding: 0;
    }
  }

  &-settings {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 10px;
    margin-right: 10px;

    &--no-scroll {
        padding: 0;
    }

    .navigation-menu-settings__blur {
        position: absolute;
        top: 0;
        height: 100%;
        width: 35px;
        background: rgb(255,255,255);
        &--left {
            left: 0;
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
        }

        &--right {
            right: 0;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        }
    }

    .navigation-menu-settings__slide-button {
        position: absolute;
        top: 40px;
        width: 35px;
        height: 35px;
        background-color: rgb(255, 255, 255);
        border: 0;
        color: $dark-gray;
        border-radius: 35px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.16);
        z-index: 1;

        &--left {
            left: 1px;
        }

        &--right {
            right: 1px;
        }
    }
  }
}

.setting-item {
  flex: 0 0 16.6677%;
  border: 0;
  background-color: transparent;

  @include media-breakpoint-down(md) {
    flex: 0 0 33.3333%;
  }

  @include media-breakpoint-down(sm) {
    flex: 0 0 50%;
  }

  &:not(.setting-item--empty):active .setting-item-image {
    box-shadow: 0 0 rempx(8px) rgba($primary, .3);
    transform: scale(0.97);
    border-color: rgba($primary, .3);
  }

  &:not(.setting-item--empty):active .setting-item-name,
  &:not(.setting-item--empty):hover .setting-item-name {
    color: $primary;
  }

  &--empty {
    cursor: default;
  }

  &--empty &-image {
    background-color: $received-bg;
    box-shadow: none !important;
    border: 0;
  }

  &--empty &-image-object {
    display: none;
  }

  &-image {
    position: relative;
    display: flex;
    box-shadow: 0 0 rempx(12px) rgba(black, .15);
    border: 1px solid transparent;
    border-radius: rempx(8px);
    width: rempx(94px);
    height: rempx(94px);
    align-items:  center;
    padding: rempx(12px);
    transition-property: box-shadow, transform, border-color;
    transition-duration: 100ms;
    background-color: $white;
    margin: 0 auto;

    &-object {
      width: 100%;
    }
  }

  &-coming-soon-image {
    display: none;
  }

  &-name {
    font-size: rempx(14px);
    margin-top: rempx(16px);
    color: $light-gray;
    transition-property: color;
    transition-duration: 200ms;
  }
}
