.icon-preview-upload {
    width: rempx(32px);
    height: rempx(32px);
    &.large {
        width: rempx(64px);
        height: rempx(64px); }
    &.empty {
        .icon-preview-upload-upload {
            opacity: 1; } }
    &-preview {
        width: rempx(32px);
        height: rempx(32px);
        font-size: rempx(16px);
        border-radius: 50%;
        border: 2px solid $white;
        &.large {
            width: rempx(64px);
            height: rempx(64px);
            font-size: rempx(32px); } }
    &-upload {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 300ms;
        background-color: $light-gray;
        opacity: 0;
        border-radius: 50%;
        font-size: rempx(16px);
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            opacity: 1; }
        &.large {
            font-size: rempx(32px); } } }
