@mixin ticket-status($color, $border-color: $color) {
    &::before {
        border-color: $border-color !important;
        background-color: $color !important;
    }
}

@mixin ticket-status-option($color, $border-color: $color) {
    &::before {
        border-color: $border-color !important;
        background-color: transparent !important;
    }

    &.active {
        color: $color !important;
    }

    &.active::before {
        background-color: $color !important;
    }
}

@mixin color-active($color) {
    &.bg{
        background-color: $color !important;
    }

    &.color {
        color: $color !important;
    }
}

@each $color, $value in $status-colors {
    .ticket-status-#{$color} {
        @include ticket-status($value);
    }
    .ticket-status-option-#{$color} {
        @include ticket-status-option($value);
    }
}

@each $color, $value in $priority-colors {
    .ticket-status-#{$color} {
        @include ticket-status($value);
    }
    .ticket-status-option-#{$color} {
        @include ticket-status-option($value);
    }
}

@each $color, $value in $dc-colors {
    .ticket-status-#{$color} {
        @include ticket-status($value);
    }
    .ticket-status-option-#{$color} {
        @include ticket-status-option($value);
    }
}

@each $color, $value in $active-colors {
    .color-active-#{$color} {
        @include color-active($value);
    }
}
