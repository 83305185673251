$ticket-control-height: 60px;
.ticket-control {
    height: 60px;
}

.tickets {
    display: flex;
    height: calc(100% - #{$ticket-control-height});

    &-bulk-actions {
        height: calc(100% - #{$ticket-control-height} - #{$bulk-actions-height})
    }

    .ticket-list {
        flex-grow: 1;
        height: 100%;
        overflow-y: auto;
        padding-right: $simplebar-width;

        .ticket-title {
            font-size: rempx(14px);
            color: $dc-light-grey;
            font-weight: normal;
            text-align: center;

            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }

    .dateslider {
        $month-dot: 10px;
        height: 100%;
        width: 140px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        z-index: 100;
        $date-bg: #BABABA;
        $padding: 3px;

        .month {
            border: unset;
            background: unset;
            font-size: rempx(14px);
            position: relative;
            padding-right: 20px;
            text-align: left;
            margin-bottom: 50px;
            display: flex;
            z-index: 3;
            align-items: center;
            transition: all .2s;
            width: 100%;

            &:not(:first-child) {
                margin-top: 50px;
            }

            &:after {
                content: ' ';
                position: absolute;
                right: 0;
                width: $month-dot;
                height: $month-dot;
                background: $date-bg;
                border-radius: 50%;
                z-index: 3;
                transition: all .2s;
            }

            &:hover {
                &:after {
                    background: $offblack;
                }
            }

            &.active {
                font-weight: bold;

                &:after {
                    background: $offblack;
                }
            }
        }

        &:after {
            content: ' ';
            position: absolute;
            top: 0;
            right: calc(#{$month-dot} / 3);
            z-index: 2;
            background: $date-bg;
            height: 100%;
            width: 3px;
        }
    }
}

.ticket-wrapper {
    display: flex;
    max-width: 100%;

    .ticket-container {
        flex-grow: 1;
        flex-shrink: 0;
    }

    .ticket-container-view .ticket {
        background-color: #d0f2ff;
    }

    .custom-checkbox {
        flex-grow: 0;
        flex-shrink: 1;
    }
}

.homepage {
    flex-grow: 1;
    flex-shrink: 1;
}

.ticket {
    display: flex;
    width: 100%;
    background: #FFF;
    border-radius: rempx(8px);
    padding: rempx(10px);
    cursor: pointer;
    position: relative;
    align-items: center;
    font-size: rempx(14px);
    transition: all .2s;
    border-left: 6px solid $dc-light-blue;
    box-shadow: 0 1px 3px 0 rgba(101, 101, 101, 0.2);

    .split-message {
        max-width: rempx(260px);
        margin-left: rempx(16px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: rempx(8px);
    }

    .ticket-ref {
        display: flex;
        align-items: center;

        .sender {
            text-align: left;
            margin-left: rempx(8px);

            h3 {
                font-size: 1rem;
                font-weight: bold;
                margin: unset;
            }

            p {
                margin: unset;

                a {
                    font-size: 0.63rem;
                    margin-left: 0.25rem;
                }
            }
        }
    }

    &.inactive {
        opacity: 0.5;
    }

    &.active {
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);

        &:hover {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &.inside {
        color: $offblack;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);

        &:hover {
            opacity: 1;
            transform: translateY(0px);
        }
    }

    &:hover {
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        opacity: 0.75;
    }

    &.quickrespond-expanded {
        top: unset !important;
        background-color: transparent;
        box-shadow: none;
        border: none;
        justify-content: unset;
        width: calc(100% - 45px);
        margin: 0;
        padding: rempx(2px) 0;

        .subject {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    // @media (max-width: 1200px) {
    //   .split-message {
    //     display: none;
    //   }
    // }
    // @media (max-width: 1000px) {
    //   .split-message, .ticket-priority, .ticket-time, .ticket-agent {
    //     display: none;
    //   }
    // }
}

.labeled-icon {
    &-icon {
        width: rempx(32);
        height: rempx(32);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
}
