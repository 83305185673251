.datepicker-preview {
  position: relative;
  display: flex;
  margin: 0;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 0;
    cursor: pointer;
    z-index: 2;
    user-select: none;
  }

  &-days {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-end;

    &:after {
      position: absolute;
    }

    &.selected .datepicker-preview-day-number {
      background-color: $primary;
      color: $white;
    }
  }

  &-day {
    display: flex;
    flex-direction: column;

    &-number {
      position: relative;
      display: flex;
      flex-grow: 0;
      align-items: center;
      justify-content: center;
      width: rempx(27px);
      height: rempx(27px);
      background-color: $light-gray;
      color: $dark;
      border-radius: 50%;
      color: $white;
      font-size: rempx(12px);
      transition-property: color,background-color;
      transition-duration: 300ms;
    }

    &:not(:first-child) {
      margin-left: rempx(47px);
    }

    &:not(:first-child) &-number:after {
      content: "";
      position: absolute;
      right: 100%;
      top: 50%;
      width: rempx(50px);
      height: rempx(4px);
      transform: translateY(-50%);
      background-color: $primary;
    }

    &-text {
      font-size: rempx(10px);
      text-align: center;
      white-space: nowrap;
    }
  }
}
