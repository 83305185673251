.create-ticket-input {
    align-items: center;

    &.hide {
        visibility: hidden; }

    &.show {
        visibility: visible; }

    &-label {
        color: $primary;
        margin-right: rempx(8);
        font-size: rempx(14);
        text-transform: uppercase; }
    &-input {
        outline: none !important;
        background-color: transparent;
        border: 0;
        font-size: 0.75rem;
        flex: 1;
        &::-webkit-input-placeholder {
            color: $light-gray; }
        &:-moz-placeholder  /* Firefox 18- */ {
            color: $light-gray; }
        &::-moz-placeholder   /* Firefox 19+ */ {
            color: $light-gray; }
        &:-ms-input-placeholder {
            color: $light-gray; }
        &::placeholder {
            color: $light-gray; } }

    .dc-popover {
        flex: 1;
        input {
            border: 0;
            font-size: 0.75rem;
            width: 100%;
            &::-webkit-input-placeholder {
                color: $light-gray; }
            &:-moz-placeholder  /* Firefox 18- */ {
                color: $light-gray; }
            &::-moz-placeholder   /* Firefox 19+ */ {
                color: $light-gray; }
            &:-ms-input-placeholder {
                color: $light-gray; }
            &::placeholder {
                color: $light-gray; } } } }

