.datepicker {
    color: $dark;
    box-shadow: 3px 0px 15px 0 rgba(0,0,0,0.16);
    border: 0 !important;
    border-radius: rempx(8);
    padding-top: rempx(24);
    padding-bottom: rempx(24);
    padding-right: rempx(15);
    padding-left: rempx(15);
    .datepicker--pointer {}
    .datepicker--nav {
        justify-content: center;
        border-bottom: 0;
        .datepicker--nav-action {
            path {
                stroke: $dark; } }
        .datepicker--nav-title {
            font-size: rempx(12);
            color: $dark; } }
    .datepicker--content {
        .datepicker--months {
            .datepicker--cells-months {
                .datepicker--cell-month {
                    font-size: rempx(10);
                    font-weight: light;
                    .-selected- {
                        background-color: $primary;
                        color: $white;
                        border-radius: rempx(5); } } } }
        .datepicker--years {
            .datepicker--cells-years {
                .datepicker--cell-year {
                    font-size: rempx(10);
                    font-weight: light;
                    .-selected- {
                        background-color: $primary;
                        color: $white;
                        border-radius: rempx(5); } } } }
        .datepicker--days {
            .datepicker--days-names {
                .datepicker--day-name {
                    color: $dark;
                    font-size: rempx(9); } }
            .datepicker--cells-days {
                .datepicker--cell-day {
                    margin-top: rempx(2);
                    font-size: rempx(10);
                    font-weight: light;
                    .-custom- {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%; } }
                .-range-from- {
                    border-top-left-radius: 50%;
                    border-bottom-left-radius: 50%;
                    background-color: $primary;
                    .-custom- {
                        height: 84%;
                        width: 83%;
                        background-color: $white;
                        color: $primary;
                        border-radius: 50%; } }
                .-range-to- {
                    border-top-right-radius: 50%;
                    border-bottom-right-radius: 50%;
                    background-color: $primary;
                    &.-disabled- {
                        border-color: $datepicker-disabled;
                        background-color: $datepicker-disabled;
                        .-custom- {
                            color: $datepicker-disabled-text;
                            background-color: $datepicker-disabled; } }
                    .-custom- {
                        height: 84%;
                        width: 83%;
                        background-color: $white;
                        color: $primary;
                        border-radius: 50%; } }
                .-selected- {
                    background-color: $primary;
                    color: $white;
                    .-custom- {
                        border: 2px solid $primary; } }
                .-in-range- {
                    background-color: $white;
                    &.-disabled- {
                        .-custom- {
                            background-color: $datepicker-disabled;
                            color: $datepicker-disabled-text;
                            &.day-0 {
                                border-top-left-radius: 50%;
                                border-bottom-left-radius: 50%; }
                            &.day-6 {
                                border-top-right-radius: 50%;
                                border-bottom-right-radius: 50%; } } }
                    .-custom- {
                        background-color: $primary;
                        color: $white;
                        border: 0;
                        &.day-0 {
                            border-top-left-radius: 50%;
                            border-bottom-left-radius: 50%; }
                        &.day-6 {
                            border-top-right-radius: 50%;
                            border-bottom-right-radius: 50%; } } } } } }

    .datepicker--buttons {
        display: flex;
        justify-content: flex-start;
        border-top: 0;
        .datepicker--button {
            font-size: rempx(12);
            padding-top: 1.5rem;
            padding-bottom: 1.5rem; } }
    &.-bottom-center- {
        .datepicker--pointer {
            $pointer-size: 18px;
            left: calc(50% - #{$pointer-size} / 2);
            bottom: calc(100% - #{$pointer-size} / 2);
            width: $pointer-size;
            height: $pointer-size;
            box-shadow: 4px -4px 6px 0 rgba(0, 0, 0, 0.05);
            border: 0; } } }

.only-from-selected.datepicker {
    .datepicker--content {
        .datepicker--days {
            .datepicker--cells-days {
                .-range-from- {
                    &.-focus- {
                        &.-selected- {
                            border-radius: 50%; } } } } } } }

