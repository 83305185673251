.main-menu {
    .messages {
        @import "../components/quickresponse";
    }

    // NOTE: View middle
    &.medium-view {
        .messages {
            .quickrespond {
                max-width: 1262px;
            }

            .controls {
                @include expanded-buttons;
            }
        }

        .tickets {
            .dateslider {
                display: none;
            }

            .ticket-list {
                .ticket {
                    .split-message, .ticket-priority, .ticket-time, .ticket-agent {
                        display: none;
                    }

                    .ticket-ref {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }
        }

        .homepage {
            max-width: 402px;
        }

        @media (max-width: 1000px) {
            .homepage {
                max-width: 100%;
            }
            .quickrespond {
                display: none !important;
            }
        }
    }

    &.small-view {
        .messages {
            .quickrespond {
                display: none !important;
            }
        }

        .tickets {
            .dateslider {
                @media (max-width: 780px) {
                    display: none;
                }
            }

            .ticket {
                .ticket-ref {
                    .sender {
                        display: flex;
                        flex-wrap: wrap;

                        h3 {
                            margin-right: rempx(16px);
                        }
                    }
                }

                @media (max-width: 1200px) {
                    .split-message {
                        display: none;
                    }
                }
                @media (max-width: 1000px) {
                    .split-message, .ticket-priority, .ticket-time, .ticket-agent {
                        display: none;
                    }
                }
            }
        }
    }

    &.large-view {
        .tickets {
            .ticket-list {
                .ticket {
                    @media (max-width: 1600px) {
                        .split-message {
                            display: none;
                        }
                    }
                    @media (max-width: 1450px) {
                        .ticket-priority {
                            display: none;
                        }
                        .ticket-time {
                            display: none;
                        }
                    }
                    @media (max-width: 1300px) {
                        .ticket-agent {
                            display: none;
                        }
                    }
                }
            }

            .dateslider {
                @media (max-width: 1300px) {
                    display: none;
                }
            }
        }
    }
}

.homepage {
    height: calc(100vh - #{$search-height}); /* Fallback for browsers that do not support Custom Properties */
    display: flex;
    flex-direction: column;

    @include ipad-content-height;

    &-xs {
        .ticket-time {
            display: none !important;
        }

        .ticket-resolution {
            display: none !important;
        }

        .ticket-agent {
            display: none !important;
        }

        .split-message {
            display: none !important;
        }

        .ticket-priority {
            display: none !important;
        }
    }

    &-sm {
        .ticket-resolution {
            display: none !important;
        }

        .ticket-agent {
            display: none !important;
        }

        .split-message {
            display: none !important;
        }

        .ticket-priority {
            display: none !important;
        }
    }

    &-md {
        .ticket-agent {
            display: none !important;
        }

        .split-message {
            display: none !important;
        }

        .ticket-priority {
            display: none !important;
        }
    }

    &-lg {
        .split-message {
            display: none !important;
        }

        .ticket-priority {
            display: none !important;
        }
    }

    &-xl {
        .ticket-priority {
            display: none !important;
        }
    }

    .ticket-control {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .select-all {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.75rem;
        }

        .spacer {
            width: 2px;
            background: #E1E1E1;
            margin: 0 0.5rem;
            position: relative;
        }

        .sort-by {
            font-size: 0.75rem;

            select {
                border: 1px solid #E1E1E1;
                background: #FFF;
                border-radius: 5px;
                padding: 0.2rem;
                white-space: nowrap;
                font-size: 0.75rem;
            }
        }

        .left {
            display: flex;
        }
    }

    .bulk-actions-container {
        transform: translateY(100%);
        display: none;
        width: 97%;
        max-width: 600px;
        max-height: $bulk-actions-height;
        overflow-x: hidden;
        margin-right: 1rem;
        height: 0;
        box-shadow: 3px 0px 15px 0px rgba(0, 0, 0, 0.16);
        border-radius: rempx(5);
        background-color: $white;

        @media (min-width: 1000px) // quickrespond is not displayed up to 1000px
            and (max-width: 1024px) {
                max-width: 490px;
        }

        @media (min-width: 1024px)
            and (max-width: 1100px) {
                max-width: 510px;
        }

        @media (min-width: 1100px)
            and (max-width: 1246px) {
                max-width: 520px;
        }

        @media (min-width: 1150px)
            and (max-width: 1246px) {
                max-width: 536px;
        }

        @media (min-width: 1246px)
            and (max-width: 1280px) {
                max-width: 550px;
        }

        // iPad queries
        @media only screen
            and (min-device-width: 1024px)
            and (max-device-width: 1112px)
            and (orientation: landscape)
            {
                max-width: 512px;
        }

        @media only screen
            and (min-device-width: 1112px)
            and (max-device-width: 1280px)
            and (orientation: landscape)
            {
                max-width: 530px;
        }

        &.show {
            display: block;
            height: $bulk-actions-height;
            transform: translateY(-15%);
        }
    }

    .bulk-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 100%;

        .item {
            font-weight: lighter !important;
            font-size: 0.875rem !important;
            letter-spacing: 0.025rem;
            line-height: 1.31rem;
            padding: 1.3rem 1rem;
            white-space: nowrap;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            position: relative;
            height: 100%;
        }

        .item:before {
            content: '|';
            position: absolute;
            right: 0;
            color: #b4b4b4;
        }

        .item:last-child:before {
            display: none;
        }

        .item:first-child {
            background-color: $controls-bg;
            color: $dark;
            border-top-left-radius: rempx(5);
            border-bottom-left-radius: rempx(5);

            &:before {
                display: none;
            }
        }
    }
}

.message-page {
    .messages {
        padding: unset;
        @import "../components/quickresponse";

        .quickrespond {
            max-width: 100%;
        }

        .controls {
            @include expanded-buttons;
        }
    }

    .sidebar-navigation {
        &.sit-right {
            margin-top: 3rem;
            right: 0;
            width: 340px;
        }
    }
}


.has-customer-orders-sidebar .messages .quickrespond {
    flex-shrink: initial;
}

