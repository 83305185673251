@each $color, $value in $status-colors {
    .text-status-#{$color} {
        color: $value !important;
    }
}

@each $color, $value in $priority-colors {
    .text-priority-#{$color} {
        color: $value !important;
    }
}

@each $color, $value in $dc-colors {
    .text-color-#{$color} {
        color: $value !important;
    }
}
