// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/bootstrap";

// Font awesome
//@import "~@fortawesome/fontawesome-free/scss/fontawesome";
//@import '~@fortawesome/fontawesome-free/scss/solid';
//@import '~@fortawesome/fontawesome-free/scss/brands';

// NProgress
@import "~nprogress/nprogress.css";

// Mixins
@import "mixins/buttons";
@import "mixins/filter_options";
@import "mixins/borders";
@import "mixins/background";
@import "mixins/text";
@import "mixins/ticket_status";
@import "mixins/unit_transforms";
@import "mixins/ipad";

// Styles
@import "styles";

// Helpdesk
@import "helpdesk/custom";

// My account
@import "helpdesk/my-account";
// Manage
@import "helpdesk/manage";
// Auth
@import "auth/auth";

// Create Ticket
@import "helpdesk/pages/create-ticket";

// Canned Responses List
@import "helpdesk/pages/canned-responses";

// Components
@import "helpdesk/components/navigation-menu";
@import "helpdesk/components/datepicker-preview";
@import "helpdesk/components/user";
@import "helpdesk/components/carousel";
@import "helpdesk/components/verification-sign";
@import "helpdesk/components/color-picker";
@import "helpdesk/components/icon-picker";
@import "helpdesk/components/icon-preview";
@import "helpdesk/components/icon-preview-upload";
@import "helpdesk/components/activity";
@import "helpdesk/components/popover";
@import "helpdesk/components/datepicker";
@import "helpdesk/components/email-select";
@import "helpdesk/components/color-list-item";
@import "helpdesk/components/spinner";
@import "helpdesk/components/auditlogs";
//@import "helpdesk/components/ticket-row";
@import "helpdesk/components/customer-orders-sidebar";
@import "helpdesk/components/respond-attachment";
@import "helpdesk/components/quick-respond-popup.sass";
@import "helpdesk/components/message-textarea.scss";
@import "helpdesk/components/switch-checkbox.scss";
@import "helpdesk/components/dialog.scss";

// Utilities
@import "utilities";
